import { Modal, Button } from 'react-bootstrap';
import React from 'react';
import AnexarComprovante from '~/Pages/AnexarComprovante';
import { FaWindowClose } from 'react-icons/fa';
const ModalAnexarComprovante = ({
  mostrarModal,
  setMostrarModal,
  ge,
  preVenda,
}) => {
  return (
    <Modal
      show={mostrarModal}
      onHide={() => setMostrarModal(false)}
      size={'xl'}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Anexar comprovante</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: '#f1f1f1' }}>
        <AnexarComprovante
          match={{
            params: {
              ge: String(ge),
              prevenda: String(preVenda),
            },
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <>
          <Button
            variant={'light'}
            type="submit"
            className="d-inline-flex align-items-center"
            onClick={() => {
              setMostrarModal(false);
            }}
          >
            <FaWindowClose />
            <span className="ml-2 d-xs-none">Fechar</span>
          </Button>
        </>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAnexarComprovante;

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as api from '~/Services/Service';
import { Container, Button, Row, Col } from 'react-bootstrap';
import Loading from '../../Components/Loading';
import { useEffect } from 'react';
import Ocorrencia from '~/Components/Proposta/Ocorrencia';
import { FaExclamationTriangle } from 'react-icons/fa';
import AuthStorage from '~/Utils/AuthStorage';

export default function DetalhesEstorno({ proposta, ativo, corretorVenda }) {
  const [propostas, setPropostas] = useState([]);
  const [propostasFiltro, setPropostasFiltro] = useState([]);
  const [exibeDetalhes, setExibeDetalhes] = useState(null);
  const [confirmaEstorno, setConfirmaEstorno] = useState(null);
  const [exibeListaOcorrencia, setExibeListaOcorrencia] = useState(null);
  const [exibeDetalheOcorrencia, setExibeDetalheOcorrencia] = useState(null);
  const [revalidaTicket, setRevalidaTicket] = useState(null);
  const [justificativa, setJustificativa] = useState('');
  const [estornandoProposta, setEstornandoProposta] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [justificativaParaSolicitar, setJustificativaParaSolicitar] = useState(
    ''
  );
  const podeEstornar = AuthStorage.temCaracteristica(551);

  const bloquearSolicitacaoEstorno = () => {
    if (isLoading) {
      return true;
    }
    if (justificativaParaSolicitar.length < 11) {
      return true;
    }
    if (podeEstornar) {
      return false;
    }
    if (AuthStorage.getUser().usuario === corretorVenda) {
      return false;
    }
    return true;
  };

  const bloquearEstornar = () => {
    if (isLoading) {
      return true;
    }
    if (justificativaParaSolicitar.length < 11) {
      return true;
    }
    if (podeEstornar) {
      return false;
    }
    return true;
  };

  const estornarProposta = async proposta => {
    setIsLoading(true);
    const request = {
      proposta,
      revalida_ticket: !!revalidaTicket,
      justificativa,
    };
    setEstornandoProposta(true);
    try {
      await api.estornarProposta(request);
      toast.success('Proposta estornada com sucesso');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
    setEstornandoProposta(false);
  };

  const solicitarEstorno = async () => {
    setIsLoading(true);
    try {
      const request = {
        codigo_proposta: proposta,
        codigo_ativo: ativo.codigo,
        justificativa: justificativaParaSolicitar,
      };
      const response = await api.solicitarEstorno(request);
      if (response) {
        toast.success(response.message);
        getPropostas(proposta);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleExibirDetalhes = ({ proposta, justificativa }) => {
    setExibeListaOcorrencia(null);
    setExibeDetalheOcorrencia(null);
    setConfirmaEstorno(null);
    setRevalidaTicket(null);
    setJustificativa('');

    if (proposta === exibeDetalhes) setExibeDetalhes(null);
    else {
      setExibeDetalhes(proposta);
      setJustificativa(justificativa);
    }
  };

  const toggleExibirDetalhesOcorrencia = codigo => {
    if (codigo === exibeDetalheOcorrencia) setExibeDetalheOcorrencia(null);
    else setExibeDetalheOcorrencia(codigo);
  };

  const getPropostas = async proposta => {
    setIsLoading(true);
    let novasPropostas = await api.getSolicitacaoParaEstornoProposta(proposta);
    setPropostas(novasPropostas);
    setPropostasFiltro(novasPropostas);
    setIsLoading(false);
  };

  useEffect(() => {
    getPropostas(proposta);
  }, []);

  const getColorStyle = ({ proposta = 0, cor = '' }) => {
    let selectoStyle = `.proposta-${proposta}::before{ border-left-color: ${cor} }`;
    return <style dangerouslySetInnerHTML={{ __html: selectoStyle }}></style>;
  };

  return (
    <>
      {' '}
      <Container fluid className="border rounded p-4 bg-light shadow-sm mb-4">
        <div>
          <h5>Visualizar/Aprovar Estorno</h5>
          {isLoading && (
            <Loading label="Buscando solicitação de estono para a proposta..." />
          )}
          {!isLoading && propostas.length === 0 && (
            <div className="text-center mt-3">
              Nenhuma solicitação encontrada para a proposta N° {proposta}
            </div>
          )}
          {propostas.length > 0 && (
            <Container>
              <div className="row mt-1">
                <div className="col-12"></div>
                <div className="text-muted ml-3 mt-1">
                  {propostasFiltro.length > 0
                    ? `Exibindo ${propostasFiltro.length} solicitações de estorno para a proposta N° ${proposta}`
                    : `Nenhuma solicitação encontrada para a proposta N° ${proposta}`}
                </div>
              </div>
              <div className="row mt-2">
                {propostasFiltro.map((item, index) => (
                  <div className="col-12" key={index}>
                    <div
                      className={`mb-2 border-0 arrow-bottom-left proposta-${item.proposta} card`}
                    >
                      {getColorStyle(item)}
                      <div
                        className="mx-3 my-1"
                        onClick={() => toggleExibirDetalhes(item)}
                      >
                        <ul className="list-unstyled d-flex mb-1">
                          <li>
                            <small className="d-block text-muted">
                              Proposta
                            </small>
                            <strong>{item.proposta}</strong>
                          </li>
                          <li className="mr-2 ml-auto">
                            <small className="d-block text-muted">
                              Corretor
                            </small>
                            <span>{item.corretor}</span>
                          </li>
                          <li>
                            <small className="d-block text-muted">
                              Imobiliária
                            </small>
                            <span
                              className="bullet"
                              style={{ backgroundColor: item.cor }}
                            ></span>
                            &nbsp;
                            {item.imobiliaria}
                          </li>
                        </ul>
                        <ul className="list-unstyled d-flex mb-1">
                          <li className="mr-2">
                            <small className="d-block text-muted">
                              Empreendimento
                            </small>
                            <strong>{item.empreendimento}</strong>
                          </li>
                          <li className="mr-2 ml-auto">
                            <small className="d-block text-muted">Quadra</small>
                            <strong>{item.quadra}</strong>
                          </li>
                          <li>
                            <small className="d-block text-muted">Lote</small>
                            <strong>{item.lote}</strong>
                          </li>
                        </ul>
                        <ul className="list-unstyled d-flex mb-0">
                          <li className="mr-2">
                            <small className="d-block text-muted">
                              Cliente
                            </small>
                            <span>{item.cliente}</span>
                          </li>
                          <li className="mr-2 ml-auto">
                            <small className="d-block text-muted">Conta</small>
                            <span>{item.codigo_conta}</span>
                          </li>
                          <li className="mr-2">
                            <small className="d-block text-muted">GE</small>
                            <span>{item.ge}</span>
                          </li>
                        </ul>
                      </div>

                      {exibeDetalhes === item.proposta && (
                        <>
                          <div className="item_detalhes-solicitacao mt-1 mb-2 py-3">
                            <div className="text-center">
                              <span className="text-muted">Justificativa</span>
                              <div className="row">
                                <div className="col-12">
                                  {item.justificativa}
                                </div>
                              </div>
                            </div>
                            <hr className="doted" />
                            <div className="d-flex flex-column">
                              {exibeListaOcorrencia !== item.proposta && (
                                <>
                                  <Ocorrencia
                                    ocorrencia={item.ativo_ocorrencia[0]}
                                    exibirDetalhes={
                                      exibeDetalheOcorrencia ===
                                      item.ativo_ocorrencia[0].data
                                    }
                                    onClick={() =>
                                      toggleExibirDetalhesOcorrencia(
                                        item.ativo_ocorrencia[0].data
                                      )
                                    }
                                  />

                                  <button
                                    type="button"
                                    className="btn btn-outline-primary mx-auto mt-2"
                                    onClick={() =>
                                      setExibeListaOcorrencia(item.proposta)
                                    }
                                  >
                                    Ver mais ocorrências
                                  </button>
                                </>
                              )}

                              {exibeListaOcorrencia === item.proposta &&
                                item.ativo_ocorrencia.map(
                                  (itemOcorrencia, index) => (
                                    <Ocorrencia
                                      ocorrencia={itemOcorrencia}
                                      key={index}
                                      exibirDetalhes={
                                        exibeDetalheOcorrencia ===
                                        itemOcorrencia.data
                                      }
                                      onClick={() =>
                                        toggleExibirDetalhesOcorrencia(
                                          itemOcorrencia.data
                                        )
                                      }
                                    />
                                  )
                                )}
                            </div>
                          </div>
                          {estornandoProposta && (
                            <div className="text-center mb-3">
                              Estornando proposta...
                            </div>
                          )}

                          {!estornandoProposta &&
                            confirmaEstorno !== item.proposta && (
                              <div className="row mb-3 btn-center">
                                <Button
                                  variant="warning"
                                  className="btn-estornar"
                                  disabled={bloquearEstornar()}
                                  onClick={() =>
                                    setConfirmaEstorno(item.proposta)
                                  }
                                >
                                  ESTORNAR
                                </Button>
                              </div>
                            )}

                          {!estornandoProposta &&
                            confirmaEstorno === item.proposta && (
                              <>
                                <div className="row  px-3">
                                  <div className="col-12">
                                    {item.revalida_ticket && (
                                      <div className="custom-control custom-checkbox">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="chkRevalidarTicket"
                                          name="chkRevalidarTicket"
                                          onChange={e =>
                                            setRevalidaTicket(e.target.checked)
                                          }
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="chkRevalidarTicket"
                                        >
                                          Revalidar ticket
                                        </label>
                                      </div>
                                    )}
                                    <div className="form-group">
                                      <label htmlFor="exampleFormControlTextarea1">
                                        Justificativa
                                      </label>
                                      <textarea
                                        className="form-control"
                                        id="exampleFormControlTextarea1"
                                        rows="3"
                                        onChange={e =>
                                          setJustificativa(e.target.value)
                                        }
                                        value={justificativa}
                                      />
                                      {justificativa.length < 11 && (
                                        <small className="feedback-text text-danger">
                                          Justifique o motivo do estorno.
                                        </small>
                                      )}
                                    </div>
                                    <div className="text-center">
                                      <span>
                                        Confirma estorno da proposta{' '}
                                        <strong>{item.proposta}</strong>?
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mb-3 btn-center">
                                  <Button
                                    variant="primary mr-4"
                                    onClick={() => setConfirmaEstorno(null)}
                                  >
                                    CANCELAR
                                  </Button>
                                  <Button
                                    variant="primary"
                                    disabled={bloquearEstornar()}
                                    onClick={() =>
                                      estornarProposta(item.proposta)
                                    }
                                  >
                                    CONFIRMAR
                                  </Button>
                                </div>
                              </>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Container>
          )}
        </div>
      </Container>
      {propostasFiltro.length === 0 && !isLoading && (
        <Container fluid className="border rounded p-4 bg-light shadow-sm mb-4">
          <div>
            <h5>Solicitar Estorno</h5>
            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">
                Justificativa para solicitação
              </label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea2"
                rows="3"
                onChange={e => setJustificativaParaSolicitar(e.target.value)}
                value={justificativaParaSolicitar}
              />
              {justificativaParaSolicitar.length < 11 && (
                <small className="feedback-text text-danger">
                  Informe no mínimo 11 caracteres para justificar a solicitação
                  de estorno.
                </small>
              )}
            </div>
            <Row className="justify-content-end">
              <Col xs={12} sm={6} md={'auto'}>
                <Button
                  variant={'danger'}
                  type="submit"
                  className="d-inline-flex align-items-center"
                  onClick={() => {
                    solicitarEstorno();
                  }}
                  disabled={bloquearSolicitacaoEstorno()}
                >
                  <FaExclamationTriangle />
                  <span className="ml-2 d-xs-none">Solicitar</span>
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </>
  );
}

import { Button, Col, Modal, Row, Container, Form } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import * as api from '~/Services/Service';
import { format, formatarMonetario } from '~/Utils/Helpers';
import { default as Selector } from 'react-select';
import { toast } from 'react-toastify';
import {
  DATETIME_DEFAULT_SHORT_FORMAT,
  EMPREENDIMENTO_LANCAMENTO,
  StatusImovel,
} from '~/constant';
import AuthStorage from '~/Utils/AuthStorage';

const ModalReserva = ({
  mostrarModal,
  setMostrarModal,
  atualizarReservados,
  atualizarMapa,
  ativo,
}) => {
  const [dadosAtivo, setDadosAtivo] = useState({
    quadra: '',
    lote: '',
    valor_vista: 0,
    valor_sinal: 0,
    area: 0,
    parcelas: 0,
    valor_parcela: 0,
    nome_corretor: '',
  });
  const [infoMesa, setInfoMesa] = useState(null);
  const [mesaSelecionada, setMesaSelecionada] = useState(null);
  const [justificativa, setHandleJustificativa] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setDadosAtivo(ativo);
    setMesaSelecionada(null);
  }, [mostrarModal]);

  const {
    quadra,
    lote,
    valor_vista,
    valor_sinal,
    area,
    parcelas,
    valor_parcela,
    nome_corretor,
    nome_cliente,
    valor_inicial_parcela_programada,
    solicitacao_estorno,
    mesa,
    data_validade,
    data_reserva,
    status,
    codigo_familia,
    codigo,
    codigo_corretor,
    codigo_prospecto,
    observacao_reserva,
    nome_usuario_mesa,
    codigo_usuario_mesa,
  } = dadosAtivo;

  const fase = codigo_familia === EMPREENDIMENTO_LANCAMENTO ? '1' : '2';
  const usuario_autorizado =
    AuthStorage.getUser().usuario === codigo_usuario_mesa;

  const pode_estornar = !!(
    solicitacao_estorno === 0 &&
    status !== StatusImovel.Bloqueado &&
    status !== StatusImovel.Disponivel &&
    status !== StatusImovel.Reservado &&
    usuario_autorizado
  );

  const temCaracteristica = AuthStorage.temCaracteristica(453);

  const podeExibirTransferencia =
    status === StatusImovel.Reservado &&
    temCaracteristica &&
    AuthStorage.getUser().usuario == codigo_corretor;

  const mostrarInfosReserva =
    status !== StatusImovel.Bloqueado && status !== StatusImovel.Disponivel;

  const podeReservar = status === StatusImovel.Disponivel;

  useEffect(() => {
    if (!mostrarModal) return;
    (async () => {
      try {
        const response = await api.pegarInformacoesMapa(
          EMPREENDIMENTO_LANCAMENTO
        );
        const info_mesas = response.map(infoMesa => {
          return {
            label: `${infoMesa.descricao}`,
            value: {
              prospecto: infoMesa.codigo_prospecto,
              usuario: infoMesa.codigo_usuario,
              mesa: infoMesa.mesa,
            },
          };
        });
        setInfoMesa(info_mesas);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [mostrarModal]);

  useEffect(() => {
    if (mostrarModal == false && atualizarReservados != undefined) {
      atualizarReservados();
      atualizarMapa();
    }
  }, [mostrarModal]);

  const handleClick = async () => {
    setIsLoading(true);
    const objResponse = {
      codigo_ativo: ativo.codigo,
      justificativa: justificativa || null,
    };
    try {
      const response = await api.reservarLote(objResponse);
      toast.success(response.message);
      const loteElement = document.getElementById(ativo.codigo);
      loteElement.classList.add('reservado');
      loteElement.classList.remove('disponivelF1', 'disponivelF2');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
    setMostrarModal(false);
  };
  const handleClickEstornar = async () => {
    setIsLoading(true);
    try {
      const response = await api.estornarLote(ativo.codigo);
      toast.success(response.message);
      setMostrarModal(false);
      atualizarReservados();
      atualizarMapa();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleTransferencia = async () => {
    setIsLoading(true);
    const objResponse = {
      codigo_ativo: codigo,
      prospecto: mesaSelecionada.prospecto,
      codigo_usuario: mesaSelecionada.usuario,
    };
    try {
      const response = await api.transferenciaLote(objResponse);
      toast.success(response.message);
      setMostrarModal(false);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const obterStatusDoAtivo = (status, familia) => {
    const statusStyles = {
      [StatusImovel.Disponivel]: {
        text: 'Disponível',
        style: {
          borderRadius: '8px',
          padding: '1px 6px',
          background:
            familia == EMPREENDIMENTO_LANCAMENTO ? '#1C9BFF' : '#E97500',
          color: '#fff',
        },
      },
      [StatusImovel.Bloqueado]: {
        text: 'Bloqueado',
        style: {
          borderRadius: '8px',
          padding: '1px 6px',
          background: 'black',
          color: '#fff',
        },
      },
      [StatusImovel.Vendido]: {
        text: 'Vendido',
        style: {
          borderRadius: '8px',
          padding: '1px 6px',
          background: '#FF0000',
          color: '#fff',
        },
      },
      [StatusImovel.PreVenda]: {
        text: 'Vendido',
        style: {
          borderRadius: '8px',
          padding: '1px 6px',
          background: '#FF0000',
          color: '#fff',
        },
      },
      [StatusImovel.PropostaRapida]: {
        text: 'Vendido',
        style: {
          borderRadius: '8px',
          padding: '1px 6px',
          background: '#FF0000',
          color: '#fff',
        },
      },
      [StatusImovel.Reservado]: {
        text: 'Reservado',
        style: {
          borderRadius: '8px',
          padding: '1px 6px',
          background: '#FFDC15',
          color: '#fff',
        },
      },
    };

    const currentStatus = statusStyles[status];
    if (currentStatus) {
      return (
        <>
          Situação:{' '}
          <strong style={currentStatus.style}>{currentStatus.text}</strong>
        </>
      );
    }
    return null;
  };

  return (
    <Modal show={mostrarModal} onHide={() => setMostrarModal(false)} size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          Detalhes do lote {lote} na quadra {quadra}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Container className="ml-2">
          <div className="mt-1">
            <h5 className="text-center">Informações do terreno</h5>
            <Row>
              <Col md="6">
                <small
                  style={{
                    borderRadius: '8px',
                    padding: '1px 6px',
                    background: fase == 1 ? '#1C98FC' : '#EE7219',
                    color: '#fff',
                  }}
                >
                  SetValley <strong>{fase}</strong> / Ativo:{' '}
                  <strong>{codigo}</strong>
                </small>
              </Col>
              <Col md="6">
                <small>
                  Quadra: <strong>{quadra}</strong> / Lote:{' '}
                  <strong>{lote}</strong>
                </small>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <small>{obterStatusDoAtivo(status, codigo_familia)}</small>
              </Col>
              <Col md="6">
                <small>
                  Entrada: <strong>{formatarMonetario(valor_sinal)}</strong>
                </small>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <small>
                  Área: <strong>{area}m²</strong>
                </small>
              </Col>
              <Col md="6">
                {valor_inicial_parcela_programada > 0 && (
                  <small>
                    Programadas:{' '}
                    <strong>
                      {formatarMonetario(valor_inicial_parcela_programada)}
                    </strong>
                  </small>
                )}
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <small>
                  Valor à vista:{' '}
                  <strong>{formatarMonetario(valor_vista)}</strong>
                </small>
              </Col>
              <Col md="6">
                <small>
                  Financ.:{' '}
                  <strong>
                    {parcelas} x {formatarMonetario(valor_parcela)}
                  </strong>
                </small>
              </Col>
            </Row>
            {solicitacao_estorno > 0 && (
              <Row>
                <Col md="12 text-center">
                  <div className="badge badge-warning w-100">
                    Aguardando estorno de proposta.
                  </div>
                </Col>
              </Row>
            )}
          </div>
          {mostrarInfosReserva && (
            <div>
              <hr className="mb-2" />
              <h5 className="text-center">Informações da reserva</h5>
              {mesa && (
                <Row>
                  <Col>
                    <small>
                      Mesa: <strong>{mesa + ' - ' + nome_usuario_mesa}</strong>
                    </small>
                  </Col>
                </Row>
              )}
              {nome_corretor && (
                <Row>
                  <Col>
                    <small>
                      Corretor:{' '}
                      <strong>{codigo_corretor + ' - ' + nome_corretor}</strong>
                    </small>
                  </Col>
                </Row>
              )}
              {nome_cliente && (
                <Row>
                  <Col>
                    <small>
                      Nome:{' '}
                      <strong>{codigo_prospecto + ' - ' + nome_cliente}</strong>
                    </small>
                  </Col>
                </Row>
              )}

              <Row>
                <Col md="6">
                  <small>
                    Reserva:{' '}
                    <strong>
                      {format.date(data_reserva, DATETIME_DEFAULT_SHORT_FORMAT)}
                    </strong>
                  </small>
                </Col>
                <Col md="6">
                  <small>
                    Validade:{' '}
                    <strong>
                      {format.date(
                        data_validade,
                        DATETIME_DEFAULT_SHORT_FORMAT
                      )}
                    </strong>
                  </small>
                </Col>
              </Row>
            </div>
          )}
          {podeExibirTransferencia && (
            <div className="mt-3 mb-3">
              <hr />
              <Row>
                <Col md={'8'}>
                  <Form.Label>Mesa para transferência</Form.Label>
                  <Selector
                    options={infoMesa || []}
                    placeholder={'Selecione uma mesa'}
                    onChange={e => {
                      setMesaSelecionada(e.value);
                    }}
                  ></Selector>
                </Col>
                <Col md={'4'} className="">
                  <Form.Label>Prospecto</Form.Label>
                  <Form.Control
                    value={(mesaSelecionada && mesaSelecionada.prospecto) || ''}
                    disabled
                  ></Form.Control>
                </Col>
              </Row>
            </div>
          )}
          {temCaracteristica && (
            <>
              <hr />
              <Row className="mt-3 mb-3">
                <Col md="12">
                  <Form.Control
                    value={
                      observacao_reserva
                        ? observacao_reserva
                        : podeReservar
                        ? justificativa
                        : 'Não informado'
                    }
                    disabled={!podeReservar}
                    onChange={e => {
                      setHandleJustificativa(e.target.value);
                    }}
                    placeholder="Informe o motivo da reserva"
                  ></Form.Control>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer
        style={{ justifyContent: pode_estornar ? 'space-between' : 'right' }}
      >
        {pode_estornar && (
          <>
            <Button
              variant={'info'}
              disabled={isLoading}
              onClick={handleClickEstornar}
            >
              {isLoading ? <>Estornando...</> : <>Estornar lote</>}
            </Button>
          </>
        )}
        {podeReservar && (
          <>
            <Button
              variant={'primary'}
              disabled={isLoading}
              onClick={handleClick}
            >
              {isLoading ? <>Reservando...</> : <>Reservar</>}
            </Button>
          </>
        )}
        {mesaSelecionada && podeExibirTransferencia && (
          <>
            {' '}
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={handleTransferencia}
            >
              {isLoading ? <>Transferindo...</> : <>Transferir lote</>}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalReserva;

import { Modal, Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import * as api from '~/Services/Service';
import { toast } from 'react-toastify';
import { URLV1 } from '../Navbar/links';
import { FaWindowClose } from 'react-icons/fa';
import Loading from '../../Components/Loading';

const ModalDocumentos = ({ mostrarModal, setMostrarModal, preVenda }) => {
  const [temBoleto, setTemBoleto] = useState(false);
  const [preVendaDocumentos, setPreVendaDocumentos] = useState(null);

  useEffect(() => {
    verificarBoletoPreVenda();
    buscarDocumentos();
  }, []);

  const verificarBoletoPreVenda = async () => {
    try {
      const response = await api.verificarBoletoPreVenda(preVenda);
      if (response !== null && response !== undefined) {
        if (response === true) {
          setTemBoleto(true);
        } else {
          setTemBoleto(false);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const buscarDocumentos = async () => {
    try {
      const response = await api.buscarDocumentosPreVenda(preVenda);
      if (response) {
        setPreVendaDocumentos(response);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const AbrirTodosDocumentos = item => {
    const todasUrls = [
      ...item.documentos_para_assinatura.map(doc => doc.url),
      ...item.documentos_extras.map(doc => doc.url),
    ].filter(url => url);

    if (temBoleto) {
      todasUrls.push(
        URLV1 + '/Impressao/SelecionarBoletosSinal.aspx?PV=' + preVenda
      );
    }
    todasUrls.forEach(url => window.open(url, '_blank'));
  };

  return (
    <div>
      {preVendaDocumentos && (
        <Modal
          show={mostrarModal}
          onHide={() => setMostrarModal(false)}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Documentos disponíveis</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <section>
              <strong className="d-block">
                Documentos a serem enviados para assinatura
              </strong>
              {preVendaDocumentos.documentos_para_assinatura && (
                <ul className="list-group mt-2">
                  {preVendaDocumentos.documentos_para_assinatura.map(
                    (nomeLink, index) => (
                      <li key={index} className="list-group-item">
                        <a
                          href={nomeLink.url}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {' '}
                          {nomeLink.nome}
                        </a>
                      </li>
                    )
                  )}
                </ul>
              )}
              {temBoleto && (
                <div className="mt-2">
                  <strong className="d-block mt-2">Títulos</strong>
                  <ul className="list-group mt-2">
                    <li key={0} className="list-group-item">
                      <a
                        href={
                          URLV1 +
                          '/Impressao/SelecionarBoletosSinal.aspx?PV=' +
                          preVenda
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {' '}
                        Imprimir Boletos{' '}
                      </a>
                    </li>
                  </ul>
                </div>
              )}
              {preVendaDocumentos.documentos_extras &&
                preVendaDocumentos.documentos_extras.length > 0 && (
                  <div className="mt-2">
                    <strong className="d-block mt-2">Documentos extras</strong>
                    <ul className="list-group mt-2">
                      {preVendaDocumentos.documentos_extras.map(
                        (nomeLink, index) => (
                          <li key={index} className="list-group-item">
                            <a
                              href={nomeLink.url}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              {' '}
                              {nomeLink.nome}{' '}
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )}
              <Button
                variant="primary"
                type="button"
                className="mt-4"
                onClick={() => {
                  AbrirTodosDocumentos(preVendaDocumentos);
                }}
              >
                <FaPlus />
                <span className="ml-2 d-xs-none">Abrir todos</span>
              </Button>
            </section>
          </Modal.Body>
          <Modal.Footer>
            <>
              <Button
                variant={'light'}
                type="submit"
                className="d-inline-flex align-items-center"
                onClick={() => {
                  setMostrarModal(false);
                }}
              >
                <FaWindowClose />
                <span className="ml-2 d-xs-none">Fechar</span>
              </Button>
            </>
          </Modal.Footer>
        </Modal>
      )}
      {!preVendaDocumentos && (
        <div>
          <Loading></Loading>
        </div>
      )}
    </div>
  );
};

export default ModalDocumentos;

import React, { useEffect, useState } from 'react';
import { Button, Table, Modal } from 'react-bootstrap';
import { FaSearch, FaTrash } from 'react-icons/fa';
import ModalReserva from './ModalReserva';
import * as api from '~/Services/Service';
import { toast } from 'react-toastify';
import { format } from '~/Utils/Helpers';
import {
  DATETIME_DEFAULT_SHORT_FORMAT,
  DATETIME_DEFAULT_SUPER_SHORT_FORMAT,
} from '~/constant';

const LotesVendas = ({
  ativosReservados,
  atualizarReservados,
  atualizarMapa,
  ativos,
  setAtivosFiltrados,
}) => {
  const [mostrarModal, setMostrarModal] = useState(null);
  const [mostrarModalConfirmacao, setMostrarModalConfirmacao] = useState(false);
  const [ativoSelecionado, setAtivoSelecionado] = useState(false);
  const [prospectoAtual, setProspectoAtual] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const prospecto = ativosReservados.find(
      reserva => reserva.codigo_prospecto != null
    );
    setProspectoAtual(prospecto);
  }, [ativosReservados]);

  const handleClickVenda1 = () => {
    window.location.href = `/vendas/prospecto/${prospectoAtual.codigo_prospecto}/empreendimento/11452`;
  };

  const handleClickVenda2 = () => {
    window.location.href = `/vendas/prospecto/${prospectoAtual.codigo_prospecto}/empreendimento/11453`;
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await api.excluirReserva(ativoSelecionado.codigo);
      toast.success(response.message);
      atualizarMapa();
      atualizarReservados();
      setMostrarModalConfirmacao(false);
    } catch (error) {
      toast.error(error);
      setMostrarModalConfirmacao(false);
    } finally {
      setIsLoading(false);
    }
  };

  const confirmarExclusao = id => {
    atualizarMapa();
    const ativo = ativos.find(imovel => imovel.codigo === id);
    setAtivoSelecionado(ativo);
    setMostrarModalConfirmacao(true);
  };

  const pesquisarAtivo = id => {
    atualizarMapa();
    const ativo = ativos.find(a => a.codigo === id);
    setAtivoSelecionado(ativo);
    setMostrarModal(true);
    setAtivosFiltrados([]);
  };

  const temFamilia1 =
    ativosReservados &&
    ativosReservados.some(reserva => reserva.codigo_empreendimento === 11452);
  const temFamilia2 =
    ativosReservados &&
    ativosReservados.some(reserva => reserva.codigo_empreendimento === 11453);

  return (
    <>
      <div>
        <div
          style={{
            borderRadius: '7px',
            overflow: 'hidden',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              maxHeight: '209px', // Define a altura máxima da tabela
              overflowY: 'auto', // Habilita rolagem interna no eixo Y
              flexGrow: 1, // Permite que a tabela ocupe o espaço disponível
            }}
          >
            <Table
              striped
              bordered
              responsive
              className="mb-0 table-sm text-center"
            >
              <thead
                style={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: temFamilia1
                    ? '#1c9bff'
                    : temFamilia2
                    ? '#EE7219'
                    : '#FFFFFF',
                  zIndex: 1,
                  color: 'white',
                }}
              >
                <tr>
                  <th>QD</th>
                  <th>LT</th>
                  <th>Prosp.</th>
                  <th>Validade</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {ativosReservados.length > 0 ? (
                  ativosReservados.map((reserva, index) => (
                    <tr
                      key={index}
                      style={{
                        color: 'white',
                        background:
                          reserva.codigo_empreendimento === 11452
                            ? '#1c9bff'
                            : reserva.codigo_empreendimento === 11453
                            ? '#EE7219'
                            : '#FFFFFF',
                      }}
                    >
                      <td>{reserva.mapa1}</td>
                      <td>{reserva.mapa2}</td>
                      <td>{reserva.codigo_prospecto}</td>
                      <td>
                        {format.date(
                          reserva.data_vencimento,
                          DATETIME_DEFAULT_SUPER_SHORT_FORMAT
                        )}
                      </td>
                      <td>
                        <Button
                          variant="danger"
                          size="sm"
                          className="mr-1 mb-1"
                          onClick={() =>
                            confirmarExclusao(reserva.codigo_ativo)
                          }
                        >
                          <FaTrash />
                        </Button>{' '}
                        <Button
                          variant="primary"
                          size="sm"
                          className="ml-1 mb-1"
                          onClick={() => pesquisarAtivo(reserva.codigo_ativo)}
                        >
                          <FaSearch />
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      Sem lotes reservados
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <div
            style={{
              display: 'flex',
              padding: '2px',
              justifyContent: 'end',
              marginTop: '7px',
            }}
          >
            {temFamilia1 && (
              <Button
                style={{ backgroundColor: '#1c9bff', color: 'white' }}
                onClick={handleClickVenda1}
                variant="light"
                className="mb-3"
              >
                Iniciar venda SetValley 1
              </Button>
            )}
            {temFamilia2 && (
              <Button
                style={{ backgroundColor: '#EE7219', color: 'white' }}
                onClick={handleClickVenda2}
                variant="light"
              >
                Iniciar venda SetValley 2
              </Button>
            )}
          </div>
        </div>

        {ativoSelecionado && (
          <Modal
            show={mostrarModalConfirmacao}
            onHide={() => setMostrarModalConfirmacao(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Cancelamento da reserva</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Tem certeza de que deseja excluir a reserva?</p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                }}
              >
                <small>
                  Id: <strong>{ativoSelecionado.codigo}</strong>
                </small>
                <small>
                  SetValley{' '}
                  <strong>
                    {ativoSelecionado.codigo_familia === 11452 ? '1' : '2'}
                  </strong>{' '}
                </small>
                <small>
                  Quadra: <strong>{ativoSelecionado.quadra}</strong>
                </small>
                <small>
                  Lote: <strong>{ativoSelecionado.lote}</strong>
                </small>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '8px',
                  flexWrap: 'wrap',
                  marginTop: '8px',
                  justifyContent: 'space-between',
                }}
              >
                <small>
                  Reservado:{' '}
                  <strong>
                    {format.date(
                      ativoSelecionado.data_reserva,
                      DATETIME_DEFAULT_SHORT_FORMAT
                    )}
                  </strong>
                </small>
                <small>
                  Vencimento:{' '}
                  {format.date(
                    ativoSelecionado.data_validade,
                    DATETIME_DEFAULT_SHORT_FORMAT
                  )}
                </small>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setMostrarModalConfirmacao(false)}
              >
                Não
              </Button>
              <Button
                variant="danger"
                disabled={isLoading}
                onClick={handleDelete}
              >
                {isLoading ? <>Excluindo...</> : <>Sim</>}
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {ativoSelecionado && (
          <ModalReserva
            mostrarModal={mostrarModal}
            setMostrarModal={setMostrarModal}
            ativo={ativoSelecionado}
            atualizarReservados={atualizarReservados}
            atualizarMapa={atualizarMapa}
          />
        )}
      </div>
    </>
  );
};

export default LotesVendas;

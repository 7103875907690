import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { formatarMonetario } from '~/Utils/Helpers';
import * as helpers from '../../Utils/Helpers';
import Loading from '../Loading';

const calcularPorcentagem = (valorOriginal, valorEfetivado) => {
  const diferenca = (valorEfetivado - valorOriginal) / valorOriginal;
  return new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(diferenca);
};

const obterDescricaoFase = fase => {
  switch (fase) {
    case 1:
      return 'Corretor - Cadastro Rápido';
    case 2:
      return 'Financeiro - Valor Não Entregue';
    case 3:
      return 'Secretaria de Vendas - Lançamento';
    case 4:
      return 'Corretor - Venda pendente para regularização';
    case 5:
      return 'Secretaria de Vendas - Emissão Contrato';
    case 6:
      return 'Cliente - Aguardando Assinatura';
    case 7:
      return 'Diretoria - Aguardando Assinatura';
    case 8:
      return 'Financeiro - Aguardando Quitar Sinal';
    case 9:
      return 'Cliente - Contrato Enviado';
    default:
      return '';
  }
};
const DetalhesAtivo = ({ linhaSelecionada, ativo }) => {
  return (
    <>
      {ativo && (
        <div className="mt-1">
          <h4>Informações do Terreno</h4>
          <Row className="mt-4">
            <Col md="6">
              <label>
                Empreendimento:{' '}
                <strong>{linhaSelecionada.empreendimento}</strong>
              </label>
            </Col>
            <Col md="6">
              <label>
                Ativo: <strong>{ativo.codigo}</strong>
              </label>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <label>
                Área: <strong>{ativo.area}m²</strong>
              </label>
            </Col>
            <Col md="6">
              <label>
                Quadra: <strong>{linhaSelecionada.qd}</strong> / Lote:{' '}
                <strong>{linhaSelecionada.lt}</strong>
              </label>
            </Col>
          </Row>
          <Row>
            {ativo.matricula && (
              <Col md="6">
                <label>
                  Matrícula: <strong>{ativo.matricula}</strong>
                </label>
              </Col>
            )}
            {ativo.link_prefeitura && (
              <Col md="6">
                <label>
                  Link prefeitura:{' '}
                  <a
                    href={ativo.link_prefeitura}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Acessar
                  </a>
                </label>
              </Col>
            )}
          </Row>
          <hr/>
          <h4 className="mt-4">Informações da Venda</h4>
          <Row className="mt-4">
            <Col md="6">
              <label>
                Data da venda:{' '}
                <strong>{helpers.format.datetime(ativo.data_venda)}</strong>
              </label>
            </Col>
            {ativo.codigo_prospecto_construtor > 0 && (
              <Col md="6">
                <label>
                  Prospecto construtor:{' '}
                  <strong>{ativo.codigo_prospecto_construtor}</strong>
                </label>
              </Col>
            )}
          </Row>
          <Row>
            <Col md="6">
              <label>
                Valor Venda:{' '}
                <strong>{formatarMonetario(ativo.vv_original)}</strong>
              </label>
            </Col>
            {ativo.vv_original !== ativo.vv_efetivado && (
              <>
                <Col md="6">
                  <label>
                    Valor venda efetivado:{' '}
                    <strong>{formatarMonetario(ativo.vv_efetivado)}</strong>{' '}
                  </label>
                </Col>
                <Col md="6">
                  <label>
                    Diferença valor venda:{' '}
                    <strong>
                      {calcularPorcentagem(
                        ativo.vv_original,
                        ativo.vv_efetivado
                      )}
                    </strong>
                  </label>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col md="6">
              <label>
                Sinal:{' '}
                <strong>{formatarMonetario(ativo.sinal_original)}</strong>
              </label>
            </Col>
            {ativo.sinal_original !== ativo.sinal_efetivado && (
              <>
                <Col md="6">
                  <label>
                    Sinal efetivado:{' '}
                    <strong>{formatarMonetario(ativo.sinal_efetivado)}</strong>
                  </label>
                </Col>
                <Col md="6">
                  <label>
                    Diferença valor sinal:{' '}
                    <strong>
                      {calcularPorcentagem(
                        ativo.sinal_original,
                        ativo.sinal_efetivado
                      )}
                    </strong>
                  </label>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col md="6">
              <label>
                Usuário: <strong>{ativo.usuario_proposta}</strong>
              </label>
            </Col>
            {ativo.usuario_proposta !== ativo.usuario_pre_venda && (
              <Col md="6">
                <label>
                  Usuário pré-venda: <strong>{ativo.usuario_pre_venda}</strong>
                </label>
              </Col>
            )}
          </Row>
          <Row>
            {ativo.cheque_bonus_aplicado > 0 && (
              <Col md="6">
                <label>
                  Cheque bônus aplicado:{' '}
                  <strong>{ativo.cheque_bonus_aplicado}</strong>
                </label>
              </Col>
            )}
            {ativo.codigo_desconto > 0 && (
              <Col md="6">
                <label>
                  Solicitação de desconto:{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      'https://www.revobrasil.com.br/Extranet/Descontos/Inserir.aspx?ID=' +
                      ativo.codigo_desconto
                    }
                  >
                    {ativo.codigo_desconto}
                  </a>
                </label>
              </Col>
            )}
          </Row>
          <Row>
            <Col md="6">
              <label>
                Tabela:{' '}
                <strong>
                  {ativo.codigo_tabela} - {ativo.descricao_tabela}
                </strong>
              </label>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <label>
                Fase:{' '}
                <strong>
                  {linhaSelecionada.fase} -{' '}
                  {obterDescricaoFase(linhaSelecionada.fase)}
                </strong>
              </label>
            </Col>
          </Row>
        </div>
      )}
      {!ativo && (
        <div>
          <Loading></Loading>
        </div>
      )}
    </>
  );
};

export default DetalhesAtivo;

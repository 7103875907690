import { Modal, Button } from 'react-bootstrap';
import React from 'react';
import ProspectoDetalhes from '~/Components/Prospectos/ProspectoDetalhes/ProspectoDetalhes';
import { FaWindowClose } from 'react-icons/fa';
const ModalProspecto = ({ mostrarModal, setMostrarModal, codigoProspecto }) => {
  return (
    <Modal
      show={mostrarModal}
      onHide={() => setMostrarModal(false)}
      size={'xl'}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Visualizar/Editar Prospecto</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: '#f1f1f1' }}>
        <ProspectoDetalhes
          hiddenNavbar={true}
          hiddenSearch={true}
          showCancel={true}
          prospectoID={codigoProspecto}
        />
      </Modal.Body>
      <Modal.Footer>
        <>
          <Button
            variant={'light'}
            type="submit"
            className="d-inline-flex align-items-center"
            onClick={() => {
              setMostrarModal(false);
            }}
          >
            <FaWindowClose />
            <span className="ml-2 d-xs-none">Fechar</span>
          </Button>
        </>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalProspecto;

import React, { Component } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { default as Selector } from 'react-select';
import { default as auth } from '../../Utils/AuthStorage';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  gerentesCorretores: state.corretor,
});

class SelectGerenteCorretor extends Component {
  buildOptionsCorretor = (gerente, gerentesCorretores) => {
    gerente = gerente ? gerentesCorretores.gerentesById[gerente] : null;

    if (auth.isGerente()) {
      gerente = gerentesCorretores.gerentesById[auth.getUser().usuario];
    }

    let corretores = [];

    if (gerente) {
      corretores = gerente.corretores.map(item => ({
        value: item.codigo,
        label: item.nome,
      }));
    } else {
      corretores = gerentesCorretores.listaCorretores.map(item => ({
        value: item.codigo,
        label: item.nome,
      }));
    }

    let initialItem = {
      value: '',
      label: gerente ? `Corretores de ${gerente.nome}` : 'Todos os corretores',
    };

    return [initialItem, ...corretores];
  };

  buildValueCorretor = (corretor, gerente, gerentesCorretores) => {
    if (!gerentesCorretores || gerentesCorretores.listaGerentes.length === 0)
      return {
        value: '',
        label: 'Carregando...',
      };
    return corretor
      ? {
          value: gerentesCorretores.corretoresById[corretor].codigo,
          label: gerentesCorretores.corretoresById[corretor].nome,
        }
      : {
          value: '',
          label: gerente
            ? `Corretores de ${gerentesCorretores.gerentesById[gerente].nome}`
            : 'Todos os corretores',
        };
  };

  buildOptionsGerentes = gerentesCorretores => {
    const options = gerentesCorretores.listaGerentes.map(item => ({
      value: item.codigo,
      label: item.nome,
    }));
    options.push({ value: '', label: 'Todos os Gerentes' });
    return options;
  };

  buildValueGerente = (gerente, gerentesCorretores) => {
    if (!gerentesCorretores || gerentesCorretores.listaGerentes.length === 0)
      return {
        value: '',
        label: 'Carregando...',
      };
    return gerente
      ? {
          value: gerentesCorretores.gerentesById[gerente].codigo,
          label: gerentesCorretores.gerentesById[gerente].nome,
        }
      : {
          value: '',
          label: 'Todos os gerentes',
        };
  };

  render() {
    const {
      gerentesCorretores,
      corretor,
      gerente,
      administradoresLancamento,
      onChangeGerente,
      ColSizeMd = 12,
      onChangeCorretor,
      disabledCorretor,
      disabledGerente,
      showLabels = false,
      mostrarParaCorretor = false,
    } = this.props;

    const user = auth.getUser();
    let defaultGerente = null;
    if (auth.isGerente()) defaultGerente = user.usuario;
    if (auth.isCorretor()) defaultGerente = user.gerente;

    let defaultCorretor = null;
    if (auth.isCorretor()) defaultCorretor = user.usuario;

    const canShow = auth.isADM() || auth.isGerente() || mostrarParaCorretor;
    if (!canShow) return null;
    return (
      <Row>
        <Col md={ColSizeMd}>
          <Form.Group controlId="search.gerente">
            <Form.Label srOnly={!showLabels}>Gerente</Form.Label>
            <Selector
              noOptionsMessage={() => 'Nenhum gerente encontrado'}
              name="gerente"
              placeholder="Pesquisar gerentes..."
              onChange={option => {
                onChangeGerente(option.value);
                onChangeCorretor('');
              }}
              isDisabled={
                auth.isCorretor() ||
                auth.isGerente() ||
                disabledGerente ||
                administradoresLancamento
              }
              options={this.buildOptionsGerentes(gerentesCorretores)}
              value={this.buildValueGerente(
                defaultGerente || gerente,
                gerentesCorretores
              )}
            ></Selector>
          </Form.Group>
        </Col>
        <Col md={ColSizeMd}>
          <Form.Group controlId="search.corretor">
            <Form.Label srOnly={!showLabels}>Corretor</Form.Label>
            <Selector
              noOptionsMessage={() => 'Nenhum corretor encontrado'}
              name="corretor"
              placeholder="Pesquisar corretores..."
              onChange={option => {
                onChangeCorretor(option.value);
                // if (!gerente) {
                //   onChangeGerente(
                //     gerentesCorretores.corretoresById[option.value]
                //       .CodigoGerente
                //   );
                // }
              }}
              isDisabled={
                auth.isCorretor() ||
                disabledCorretor ||
                administradoresLancamento
              }
              options={this.buildOptionsCorretor(gerente, gerentesCorretores)}
              value={this.buildValueCorretor(
                defaultCorretor || corretor,
                gerente,
                gerentesCorretores
              )}
            ></Selector>
          </Form.Group>
        </Col>
      </Row>
    );
  }
}

export default connect(mapStateToProps)(SelectGerenteCorretor);

import React from 'react';
import ProspectoDetalhes from '~/Components/Prospectos/ProspectoDetalhes/ProspectoDetalhes';
export default function Pages(props) {
  return (
    <ProspectoDetalhes
      {...{
        ...props,
        prospectoID: props.match.params.id,
        onCancel: () => {
          props.history.push('/prospectos');
          // props.setProspecto(null);
        },
      }}
    />
  );
}

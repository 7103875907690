import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Form, Button, Row, Col, Table, Pagination } from 'react-bootstrap';
import {
  FaSearch,
  FaPlus,
  FaMinus,
  FaFilePdf,
  FaFileUpload,
  FaFileContract,
  FaFileExcel,
  FaChartLine,
} from 'react-icons/fa';
import { Formik, Field } from 'formik';
import Header from '~/Components/Header/Header';
import * as api from '~/Services/Service';
import { default as Selector } from 'react-select';
import DateMask from '~/Components/DateMask';
import * as constants from '~/constant';
import MaskedInput from 'react-text-mask';
import Loading from '~/Components/Loading';
import { toast } from 'react-toastify';
import ModalDocumentos from '~/Components/AdmVendas/ModalDocumentos';
import ModalPendente from '~/Components/AdmVendas/ModalPendente';
import ModalProspecto from '~/Components/AdmVendas/ModalProspecto';
import ModalAnexarComprovante from '~/Components/AdmVendas/ModalAnexarComprovante';
import ModalAssinatura from '~/Components/AdmVendas/ModalAssinatura';
import ModalDetalhes from '~/Components/AdmVendas/ModalDetalhes';
import axios from 'axios';
import LocalStorage from '~/Utils/LocalStorage';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import { createDate } from '~/Utils/Helpers';

const mapStateToProps = state => ({
  userLogged: state.auth.user,
});

const AdmVendas = () => {
  const [btnMaisFiltros, setBtnMaisFiltros] = useState(false);
  const [mostrarModal, setMostrarModal] = useState(false);
  const [propostas, setPropostas] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mostrarModalConferido, setMostrarModalConferido] = useState(false);
  const [mostrarModalProspecto, setMostrarModalProspecto] = useState(false);
  const [mostrarModalComprovante, setMostrarModalComprovante] = useState(false);
  const [mostrarModalAssinatura, setMostrarModalAssinatura] = useState(false);
  const [mostrarModalDetalhes, setMostrarModalDetalhes] = useState(false);
  const [codigoDocDigital, setCodigoDocDigital] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(20);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const validationSchema = Yup.object().shape({
    data_inicial: Yup.string()
      .matches(/^\d{2}\/\d{2}\/\d{4}$/, 'Formato de data inválido')
      .test('valid-date', 'Data inválida', value => {
        if (!value) return true;
        return moment(value, 'DD/MM/YYYY', true).isValid();
      })
      .test('data-inicial-require-pair', 'Preencha a data final', function(
        value
      ) {
        const { data_final } = this.parent;
        if (value && !data_final) {
          return false;
        }
        return true;
      }),
    data_final: Yup.string()
      .matches(/^\d{2}\/\d{2}\/\d{4}$/, 'Formato de data inválido')
      .test('valid-date', 'Data inválida', value => {
        if (!value) return true;
        return moment(value, 'DD/MM/YYYY', true).isValid();
      })
      .test('data-final-require-pair', 'Preencha a data inicial', function(
        value
      ) {
        const { data_inicial } = this.parent;
        if (value && !data_inicial) {
          return false;
        }
        return true;
      }),
  });

  const totalPages = Math.ceil(propostas.length / recordsPerPage);
  const empreendimentos = useSelector(state => state.empreendimentos);
  const gerentesCorretores = useSelector(state => state.corretor);

  const sortedPropostas = React.useMemo(() => {
    if (sortConfig.key) {
      const sorted = [...propostas].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key])
          return sortConfig.direction === 'asc' ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key])
          return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
      return sorted;
    }
    return propostas;
  }, [propostas, sortConfig]);

  const currentRecords = React.useMemo(() => {
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    return sortedPropostas.slice(indexOfFirstRecord, indexOfLastRecord);
  }, [sortedPropostas, currentPage, recordsPerPage]);

  const handleSort = column => {
    let direction = 'asc';
    if (sortConfig.key === column && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: column, direction });
  };

  const optionsEmpreendimentos = [
    { value: '', label: 'Todos empreendimentos' },
    ...empreendimentos.sort.map(item => ({
      value: item.codigo,
      label: item.codigo + ' - ' + item.descricao,
    })),
  ];

  const optionsGerentes = [
    { value: '', label: 'Todas...' },
    ...gerentesCorretores.listaGerentes.map(ger => ({
      value: ger.codigo,
      label: ger.nome_imobiliaria,
    })),
  ];

  const optionsCorretores = [
    { value: '', label: 'Todos...' },
    ...gerentesCorretores.listaCorretores.map(cor => ({
      value: cor.codigo,
      label: cor.codigo + ' - ' + cor.nome,
    })),
  ];

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const habilitarEDesabilitarMaisFiltros = () => {
    setBtnMaisFiltros(!btnMaisFiltros);
  };

  const abrirModalDocumentos = () => {
    setMostrarModal(!mostrarModal);
  };

  const abrirModalPendente = () => {
    setMostrarModalConferido(!mostrarModalConferido);
  };

  const abrirModalProspecto = () => {
    setMostrarModalProspecto(!mostrarModalProspecto);
  };

  const abrirModalComprovante = () => {
    setMostrarModalComprovante(!mostrarModalComprovante);
  };

  const abrirModalAssinatura = async pre_venda => {
    await obterCodigoDocDigital(pre_venda);
    setMostrarModalAssinatura(!mostrarModalAssinatura);
  };

  const abrirModalDetalhes = () => {
    setMostrarModalDetalhes(!mostrarModalDetalhes);
  };

  const handleRowClick = item => {
    setSelectedRow(item);
  };

  const montarObjetoPagamento = propostaSelecionada => {
    switch (propostaSelecionada.pagto) {
      case 0:
        return { cor: '#000', tooltip: 'Venda Sem Depósito / Cartão' };
      case 1:
        return { cor: '#FFC516', tooltip: 'Aguardando Comprovante' };
      case 2:
        return { cor: '#3BAF56', tooltip: 'Comprovante Inserido' };
      case 3:
        return { cor: '#1E8BFF', tooltip: 'Aprovado Sec. Vendas' };
      case 4:
        return { cor: '#619DD8', tooltip: 'Aprovado Dpto. Financeiro' };
      case 5:
        return { cor: '#1E8BFF', tooltip: 'Aprovado Sec. Vendas (Cheque)' };
      case 8:
        return { cor: '#EA9384', tooltip: 'Recusado Dpto. Financeiro' };
      case 9:
        return { cor: '#DC3545', tooltip: 'Recusado Sec. Vendas' };
      default:
        return { cor: '', tooltip: '' };
    }
  };

  const montarObjetoDocDigital = propostaSelecionada => {
    switch (propostaSelecionada.status_doc) {
      case 0:
      case 4:
      case 7:
        return { cor: '#000', tooltip: 'Solicitar Assinatura Digital' };
      case 1:
        return { cor: '#FE970A', tooltip: 'Assinatura Digital Solicitada' };
      case 2:
        return { cor: '#95B75D', tooltip: 'Assinatura Digital Aprovada' };
      case 3:
        return { cor: '#E04A4A', tooltip: 'Assinatura Digital Reprovada' };
      case 5:
        return { cor: '#007BFF', tooltip: 'Pendente Assinaturas Cliente' };
      case 6:
        return { cor: '#0747A6', tooltip: 'Pendente Assinaturas Setpar' };
      case 8:
        return { cor: '#729638', tooltip: 'Finalizado' };
      case 10:
        return { cor: '#729638', tooltip: 'Implantado/Finalizado' };
      default:
        return { cor: '#1E8BFF', tooltip: 'Acompanhar Assinatura Digital' };
    }
  };

  const montarObjetoFase = propostaSelecionada => {
    switch (propostaSelecionada.fase) {
      case 1:
        return { cor: '#C0C0C0', tooltip: 'Corretor - Cadastro Rápido' };
      case 2:
        return { cor: '#00FFFF', tooltip: 'Financeiro - Valor Não Entregue' };
      case 3:
        return {
          cor: '#C0C0C0',
          tooltip: 'Secretaria de Vendas - Lançamento',
        };
      case 4:
        return {
          cor: '#FF00FF',
          tooltip: 'Corretor - Venda pendente para regularização',
        };
      case 5:
        return {
          cor: '#FFFF00',
          tooltip: 'Secretaria de Vendas - Emissão Contrato',
        };
      case 6:
        return { cor: '#00FF00', tooltip: 'Cliente - Aguardando Assinatura' };
      case 7:
        return {
          cor: '#FFA500',
          tooltip: 'Diretoria - Aguardando Assinatura',
        };
      case 8:
        return {
          cor: '#008000',
          tooltip: 'Financeiro - Aguardando Quitar Sinal',
        };
      case 9:
        return { cor: '#FF0000', tooltip: 'Cliente - Contrato Enviado' };
      default:
        return { cor: '', tooltip: '' };
    }
  };

  const consultarAdmVendas = async filtro => {
    try {
      setLoading(true);

      var filtroLimpo = Object.fromEntries(
        Object.entries(filtro).filter(
          ([_, value]) => value !== '' && value !== null && value !== undefined
        )
      );

      if (filtro.data_inicial && filtro.data_final) {
        filtroLimpo.data_inicial = createDate(
          filtro.data_inicial,
          'DD/MM/YYYY'
        ).format(constants.DATE_FORMAT_SERVER);
        filtroLimpo.data_final = createDate(
          filtro.data_final,
          'DD/MM/YYYY'
        ).format(constants.DATE_FORMAT_SERVER);
      }

      const propostas = await api.consultarAdmVendas(filtroLimpo);
      setPropostas(propostas);
    } catch (error) {
      toast.error(error.message || 'Erro ao buscar propostas');
    } finally {
      setLoading(false);
      setCurrentPage(1);
    }
  };

  const obterCodigoDocDigital = async codigoPreVenda => {
    try {
      const retorno = await api.obterCodigoDocDigital(codigoPreVenda);
      if (retorno) {
        setCodigoDocDigital(retorno);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const salvarEmExcel = async () => {
    try {
      const request = propostas;

      let token = LocalStorage.get(constants.AUTH_KEY)
        ? 'Bearer ' + LocalStorage.get(constants.AUTH_KEY)
        : null;

      const response = await axios.post(
        constants.APIBASE + '/venda/exportar/excel',
        request,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          responseType: 'blob',
        }
      );
      if (response) {
        const header = response.headers['content-disposition'];
        const filename = header.match(/filename=(.+)/)[1];
        const blob = new Blob([response.data], {
          type: 'application/octet-stream',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div>
      <Header title="Administração de Vendas" />
      <Formik
        initialValues={{
          codigo_gerente: '',
          codigo_corretor: '',
          codigo_prospecto: '',
          pre_venda: '',
          codigo_status_digitalizacao: '',
          codigo_empreendimento: '',
          quadra: '',
          lote: '',
          codigo_venda_conferencia: '',
          tipo_assinatura: '',
          codigo_status_documento_digital: '',
          filtrar_data_por: '',
          data_inicial: '',
          data_final: '',
          fase: '',
          efetivadas: false,
          status: 'P',
          pesquisa_prevenda_respondidas: '',
        }}
        validationSchema={validationSchema}
        onSubmit={filtro => {
          consultarAdmVendas(filtro);
        }}
      >
        {({ handleSubmit, setFieldValue, errors, touched, values }) => (
          <Form
            onSubmit={handleSubmit}
            className="container-fluid bg-white p-3"
          >
            <Row>
              <Col xs={12} sm={6} md={4} lg={2}>
                <Form.Group controlId="codigo_gerente">
                  <Form.Label>Imobiliária</Form.Label>
                  <Selector
                    noOptionsMessage={() => 'Não encontrado'}
                    placeholder="Buscar..."
                    options={optionsGerentes}
                    onChange={selectedOption => {
                      setFieldValue(
                        'codigo_gerente',
                        selectedOption ? selectedOption.value : ''
                      );
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3}>
                <Form.Group controlId="codigo_corretor">
                  <Form.Label>Corretor</Form.Label>
                  <Selector
                    noOptionsMessage={() => 'Não encontrado'}
                    placeholder="Buscar..."
                    options={optionsCorretores}
                    onChange={selectedOption => {
                      setFieldValue(
                        'codigo_corretor',
                        selectedOption ? selectedOption.value : ''
                      );
                    }}
                  />
                </Form.Group>
              </Col>

              <Col xs={12} sm={6} md={3} lg={2}>
                <Form.Group controlId="codigo_prospecto">
                  <Form.Label>Prospecto</Form.Label>
                  <Field
                    type="text"
                    name="codigo_prospecto"
                    className="form-control"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={3} lg={2}>
                <Form.Group controlId="pre_venda">
                  <Form.Label>Pré-venda</Form.Label>
                  <Field
                    type="text"
                    name="pre_venda"
                    className="form-control"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3}>
                <Form.Group controlId="codigo_status_digitalizacao">
                  <Form.Label>Digitalização</Form.Label>
                  <Field
                    as="select"
                    name="codigo_status_digitalizacao"
                    className="form-control"
                  >
                    <option value="">Selecione...</option>
                    <option value="0">Venda sem Dep/Cartão/Cheque</option>
                    <option value="1">Aguardando Comprovante</option>
                    <option value="2">Comprovante Inserido</option>
                    <option value="3">Aprovado Sec. Vendas</option>
                    <option value="5">
                      Aprovado Sec. Vendas (Somente Cheque)
                    </option>
                    <option value="4">Aprovado Dpto. Financeiro</option>
                    <option value="8">Recusado Dpto. Financeiro</option>
                    <option value="9">Recusado Sec. Vendas</option>
                  </Field>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={5} lg={3}>
                <Form.Group controlId="codigo_empreendimento">
                  <Form.Label>Empreendimento</Form.Label>
                  <Selector
                    noOptionsMessage={() => 'Nenhum empreendimento encontrado'}
                    placeholder="Buscar..."
                    options={optionsEmpreendimentos}
                    onChange={selectedOption => {
                      setFieldValue(
                        'codigo_empreendimento',
                        selectedOption ? selectedOption.value : ''
                      );
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} md={2} lg={1}>
                <Form.Group controlId="quadra">
                  <Form.Label>Quadra</Form.Label>
                  <Field type="text" name="quadra" className="form-control" />
                </Form.Group>
              </Col>
              <Col xs={6} sm={6} md={2} lg={1}>
                <Form.Group controlId="lote">
                  <Form.Label>Lote</Form.Label>
                  <Field type="text" name="lote" className="form-control" />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={3} lg={2}>
                <Form.Group controlId="codigo_venda_conferencia">
                  <Form.Label>Conferência</Form.Label>
                  <Field
                    as="select"
                    name="codigo_venda_conferencia"
                    className="form-control"
                  >
                    <option value="">Selecione...</option>
                    <option value="1">Não conferido</option>
                    <option value="2">Pendente</option>
                    <option value="3">Conferido</option>
                  </Field>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={3} lg={2}>
                <Form.Group controlId="tipo_assinatura">
                  <Form.Label>Tipo assinatura</Form.Label>
                  <Field
                    as="select"
                    name="tipo_assinatura"
                    className="form-control"
                  >
                    <option value="">Selecione...</option>
                    <option value="F">Físico</option>
                    <option value="D">Digital</option>
                  </Field>
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3}>
                <Form.Group controlId="codigo_status_documento_digital">
                  <Form.Label>Contrato digital</Form.Label>
                  <Field
                    as="select"
                    name="codigo_status_documento_digital"
                    className="form-control"
                  >
                    <option value="">Selecione...</option>
                    <option value="1">Solicitado</option>
                    <option value="3">Reprovado</option>
                    <option value="5,6">
                      Pendente Assinaturas (Clientes e Setpar)
                    </option>
                    <option value="5">Pendente Assinaturas Clientes</option>
                    <option value="G:2">
                      Pendente Assinaturas Testemunhas
                    </option>
                    <option value="G:3">
                      Pendente Assinaturas Procuradores
                    </option>
                    <option value="G:4">
                      Pendente Assinaturas Validadores
                    </option>
                    <option value="6">Pendente Assinaturas Setpar</option>
                    <option value="8">Finalizado</option>
                    <option value="1,3,4,5,8">Todos</option>
                  </Field>
                </Form.Group>
              </Col>
            </Row>

            {btnMaisFiltros && (
              <Row>
                <Col xs={12} sm={6} md={3} lg={2}>
                  <Form.Group controlId="filtrar_data_por">
                    <Form.Label>Filtrar data por</Form.Label>
                    <Field
                      as="select"
                      name="filtrar_data_por"
                      className="form-control"
                    >
                      <option value="">Selecione...</option>
                      <option value="P">Proposta</option>
                      <option value="E">Efetivação</option>
                    </Field>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={3} lg={2}>
                  <DateMask
                    allowEmpty
                    onValidate={value => setFieldValue('data_inicial', value)}
                  >
                    {({ dateError, dateValue, setDate }) => (
                      <Form.Group controlId="data_inicial">
                        <Form.Label>Data inicial</Form.Label>
                        <MaskedInput
                          name="data_inicial"
                          type="tel"
                          guide={false}
                          className={`form-control`}
                          placeholder="Ex.: 01/01/2019"
                          mask={constants.MASK_DATE}
                          value={values.data_inicial}
                          onChange={e => {
                            // setDate(e.target.value);
                            setFieldValue('data_inicial', e.target.value);
                          }}
                        />
                        <Form.Control.Feedback
                          className="d-flex"
                          type="invalid"
                        >
                          {errors && errors.data_inicial}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                  </DateMask>
                </Col>
                <Col xs={12} sm={6} md={3} lg={2}>
                  <DateMask
                    allowEmpty
                    onValidate={value => setFieldValue('data_final', value)}
                  >
                    {({ dateError, dateValue, setDate }) => (
                      <Form.Group controlId="data_final">
                        <Form.Label>Data final</Form.Label>
                        <MaskedInput
                          name="data_final"
                          type="tel"
                          guide={false}
                          className={`form-control ${
                            errors.data_final && touched.data_final
                              ? 'is-invalid'
                              : ''
                          }`}
                          placeholder="Ex.: 01/01/2019"
                          mask={constants.MASK_DATE}
                          value={values.data_final}
                          onChange={e => {
                            // setDate(e.target.value);
                            setFieldValue('data_final', e.target.value);
                          }}
                        />

                        <Form.Control.Feedback
                          className="d-flex"
                          type="invalid"
                        >
                          {errors.data_final}
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                  </DateMask>
                </Col>
                <Col xs={12} sm={6} md={4} lg={4}>
                  <Form.Group controlId="fase">
                    <Form.Label>Fase</Form.Label>
                    <Field as="select" name="fase" className="form-control">
                      <option value="">Todas</option>
                      <option value="1">
                        2 - Financeiro - Valor Não Entregue
                      </option>
                      <option value="2">
                        3 - Secretaria de Vendas - Lançamento
                      </option>
                      <option value="3">
                        4 - Corretor - Venda pendente para regularização
                      </option>
                      <option value="4">
                        5 - Secretaria de Vendas - Emissão Contrato
                      </option>
                      <option value="5">
                        6 - Cliente - Aguardando Assinatura
                      </option>
                      <option value="6">
                        7 - Diretoria - Aguardando Assinatura
                      </option>
                      <option value="7">
                        8 - Financeiro - Aguardando Quitar Sinal
                      </option>
                      <option value="8">9 - Cliente - Contrato Enviado</option>
                    </Field>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={3} lg={2}>
                  <Form.Group controlId="pesquisa_prevenda_respondidas">
                    <Form.Label>Pesquisa</Form.Label>
                    <Field
                      as="select"
                      name="pesquisa_prevenda_respondidas"
                      className="form-control"
                    >
                      <option value="">Selecione...</option>
                      <option value="1">Respondidas</option>
                      <option value="0">Não respondidas</option>
                    </Field>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={3} lg={2}>
                  <Form.Group controlId="status">
                    <Form.Label>Status</Form.Label>
                    <Field as="select" name="status" className="form-control">
                      <option value="P">Pendentes</option>
                      <option value="T">Todos</option>
                    </Field>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={3} lg={2}>
                  <Form.Group controlId="efetivadas">
                    <Form.Label>Vendas efetivadas</Form.Label>
                    <Field
                      as="select"
                      name="efetivadas"
                      className="form-control"
                    >
                      <option value="false">Não</option>
                      <option value="true">Sim</option>
                    </Field>
                  </Form.Group>
                </Col>
              </Row>
            )}
            <div className="d-flex justify-content-end align-itens-center">
              <Button
                variant="outline-primary"
                type="button"
                onClick={habilitarEDesabilitarMaisFiltros}
                className="col-xs-12 col-sm-6 col-md-4 col-lg-1 mr-3"
              >
                {btnMaisFiltros ? <FaMinus /> : <FaPlus />}
                <span className="d-xs-none">
                  {btnMaisFiltros ? ' Filtros' : ' Filtros'}
                </span>
              </Button>
              <Button
                disabled={Object.values(errors).length > 0 || loading}
                variant="success"
                type="submit"
                className="col-xs-12 col-sm-6 col-md-4 col-lg-1"
              >
                <FaSearch />
                <span className="d-xs-none"> Buscar</span>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {loading && <Loading />}
      {!loading && currentRecords.length > 0 && (
        <div className="container-fluid bg-white table-responsive">
          <Table striped bordered hover className="table-sm">
            <thead>
              <tr className="text-center">
                <th
                  onClick={() => handleSort('data')}
                  style={{ cursor: 'pointer' }}
                >
                  Data
                </th>
                <th
                  onClick={() => handleSort('fase')}
                  style={{ cursor: 'pointer' }}
                >
                  Fase
                </th>
                <th
                  onClick={() => handleSort('pre_venda')}
                  style={{ cursor: 'pointer' }}
                >
                  Pré-Venda
                </th>
                <th
                  onClick={() => handleSort('usuario')}
                  style={{ cursor: 'pointer' }}
                >
                  Usuário
                </th>
                <th
                  onClick={() => handleSort('empreendimento')}
                  style={{ cursor: 'pointer' }}
                >
                  Empreendimento
                </th>
                <th
                  onClick={() => handleSort('qd')}
                  style={{ cursor: 'pointer' }}
                >
                  QD
                </th>
                <th
                  onClick={() => handleSort('lt')}
                  style={{ cursor: 'pointer' }}
                >
                  LT
                </th>
                <th
                  onClick={() => handleSort('ge_cliente')}
                  style={{ cursor: 'pointer' }}
                >
                  GE / Cliente - Nome
                </th>
                <th
                  onClick={() => handleSort('codigo_prospecto')}
                  style={{ cursor: 'pointer' }}
                >
                  Prospecto
                </th>
                <th
                  onClick={() => handleSort('assinatura')}
                  style={{ cursor: 'pointer' }}
                >
                  Assinatura
                </th>
                <th
                  onClick={() => handleSort('conferido')}
                  style={{ cursor: 'pointer' }}
                >
                  Conferido
                </th>
                <th>Impressões</th>
                <th>Pgtos.</th>
                <th>d4Sign</th>
                <th>Detalhes</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {currentRecords.length > 0 &&
                currentRecords.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      fontWeight: selectedRow === item ? 'bold' : 'normal',
                      cursor: 'pointer',
                      transition: 'background-color 0.1s ease',
                    }}
                    onClick={() => handleRowClick(item)}
                  >
                    <td>
                      {new Intl.DateTimeFormat('pt-BR').format(
                        new Date(item.data)
                      )}
                    </td>
                    <td
                      className="text-center"
                      title={montarObjetoFase(item).tooltip}
                      style={{
                        fontWeight: 'bold',
                        color: montarObjetoFase(item).cor,
                      }}
                    >
                      {item.fase}
                    </td>
                    <td>{item.pre_venda}</td>
                    <td>{item.usuario}</td>
                    <td>{item.empreendimento}</td>
                    <td>{item.qd}</td>
                    <td>{item.lt}</td>
                    <td style={{ textDecoration: 'underline' }}>
                      <i
                        onClick={() => {
                          abrirModalProspecto();
                        }}
                      >
                        {item.ge_cliente + ' - ' + item.nome_cliente}
                      </i>
                    </td>
                    <td>{item.codigo_prospecto}</td>
                    <td>{item.assinatura}</td>
                    <td
                      style={{ textDecoration: 'underline' }}
                      onClick={() => {
                        abrirModalPendente();
                      }}
                    >
                      {item.conferido}
                    </td>
                    <td
                      className="text-center"
                      onClick={() => abrirModalDocumentos()}
                    >
                      <FaFilePdf color="#e12929" size={22} />
                    </td>
                    <td
                      title={montarObjetoPagamento(item).tooltip}
                      className="text-center"
                      onClick={() =>
                        item.pagto !== 0
                          ? abrirModalComprovante()
                          : () => {
                              return;
                            }
                      }
                    >
                      <FaFileUpload
                        color={montarObjetoPagamento(item).cor}
                        size={22}
                      />
                    </td>
                    <td
                      className="text-center"
                      title={montarObjetoDocDigital(item).tooltip}
                      onClick={() => {
                        abrirModalAssinatura(item.pre_venda);
                      }}
                    >
                      <FaFileContract
                        color={montarObjetoDocDigital(item).cor}
                        size={22}
                      />
                    </td>
                    <td
                      onClick={() => {
                        abrirModalDetalhes();
                      }}
                    >
                      <FaSearch color="#0747a6" size={22} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
          <div className="d-flex justify-content-end">
            <Button
              variant="success"
              type="submit"
              onClick={() => {
                salvarEmExcel();
              }}
            >
              <FaFileExcel />
              <span className="ml-2 d-xs-none">Salvar em EXCEL</span>
            </Button>
          </div>
          <div>
            <Pagination>
              <Pagination.First
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              />
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {totalPages > 10 && currentPage > 5 && (
                <Pagination.Item onClick={() => handlePageChange(1)}>
                  1
                </Pagination.Item>
              )}
              {totalPages > 10 && currentPage > 5 && <Pagination.Ellipsis />}

              {[...Array(totalPages).keys()]
                .slice(
                  Math.max(0, currentPage - 6),
                  Math.min(totalPages, currentPage + 4)
                )
                .map(number => (
                  <Pagination.Item
                    key={number}
                    active={currentPage === number + 1}
                    onClick={() => handlePageChange(number + 1)}
                  >
                    {number + 1}
                  </Pagination.Item>
                ))}

              {totalPages > 10 && currentPage < totalPages - 4 && (
                <Pagination.Ellipsis />
              )}
              {totalPages > 10 && currentPage < totalPages - 4 && (
                <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                  {totalPages}
                </Pagination.Item>
              )}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
              <Pagination.Last
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </div>
        </div>
      )}

      {mostrarModal && (
        <ModalDocumentos
          mostrarModal={mostrarModal}
          setMostrarModal={setMostrarModal}
          preVenda={selectedRow.pre_venda}
        />
      )}
      {mostrarModalConferido && (
        <ModalPendente
          mostrarModal={mostrarModalConferido}
          setMostrarModal={setMostrarModalConferido}
          proposta={selectedRow}
          setProposta={setSelectedRow}
        />
      )}
      {mostrarModalProspecto && (
        <ModalProspecto
          mostrarModal={mostrarModalProspecto}
          setMostrarModal={setMostrarModalProspecto}
          codigoProspecto={selectedRow.codigo_prospecto}
        />
      )}
      {mostrarModalComprovante && (
        <ModalAnexarComprovante
          mostrarModal={mostrarModalComprovante}
          setMostrarModal={setMostrarModalComprovante}
          ge={selectedRow.ge_cliente.split(' / ')[0]}
          preVenda={selectedRow.pre_venda}
        />
      )}
      {mostrarModalAssinatura && (
        <ModalAssinatura
          mostrarModal={mostrarModalAssinatura}
          setMostrarModal={setMostrarModalAssinatura}
          codDocDigital={codigoDocDigital}
          codPreVenda={selectedRow.pre_venda}
          setCodigoDocDigital={setCodigoDocDigital}
        />
      )}
      {mostrarModalDetalhes && (
        <ModalDetalhes
          mostrarModal={mostrarModalDetalhes}
          setMostrarModal={setMostrarModalDetalhes}
          linhaSelecionada={selectedRow}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps)(AdmVendas);

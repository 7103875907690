import React, { useEffect, useMemo, useState } from 'react';
import { default as Selector } from 'react-select';
import { Card, Row, Col } from 'react-bootstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import PerfectScrollbar from 'react-perfect-scrollbar';
import _maxBy from 'lodash/maxBy';

import FormPlanoFinanciamento from '../FormPlanoFinanciamento';
import { format, formatarMonetario } from '~/Utils/Helpers.js';
import { BOLETO } from '~/constant';

import {
  calcularSaldoAFinanciar,
  calcularValorFuturo,
  calcularValorPresente,
  reCalcularPlanosFinanciamento,
} from '~/Pages/Vendas/helpers';
import { FaCheck, FaPlus, FaTrash } from 'react-icons/fa';
import moment from 'moment';
import ConfirmButton from '~/Components/ConfirmButton';
import ParcelasIntermediarias from '../ParcelasIntermediarias';
import { Form, Formik } from 'formik';

export default function FormularioFinanciamento({
  empreendimento,
  ativosDaTabelaSelecionadaPorCodigo,
  listaFormasDePagamento,
  adicionarParcelaFinanciamento,
  ativosSelecionados,
  ativosPorCodigo,
  financiamentos,
  totalTitulosSinal,
  tabelaSelecionada,
  readonly,
  removerTodasParcelasFinanciamento,
  taxaParcelaIntermediaria,
  taxaTabela,
  dataVenda,
  setInfoVendas,
}) {
  if (!ativosSelecionados) return null;

  const [planoFinanciamento, setPlanoFinanciamento] = useState(null);
  const [parcelasCondicionantes, setParcelasCondicionantes] = useState([]);
  const [parcelasEmSeriesEscadinha, setParcelasEmSeriesEscadinha] = useState(
    []
  );
  const [
    possuiParcelasFinanciamento,
    setPossuiParcelasFinanciamento,
  ] = useState([]);
  const [diaVencimento, setDiaVencimento] = useState(10);
  const [ultimaParcelaEscadinhas, setUltimaParcelaEscadinhas] = useState([]);
  const [exibirParcelasProgramadas, setExibirParcelasProgramadas] = useState([
    {
      codigo: 0,
      exibirParcelasProgramadas: false,
    },
  ]);
  const [
    exibirParcelasCondicionantes,
    setExibirParcelasCondicionantes,
  ] = useState([
    {
      codigo: 0,
      exibirParcelasCondicionantes: false,
    },
  ]);
  const [
    exibirParcelasIntermediarias,
    setExibirParcelasIntermediarias,
  ] = useState([
    {
      codigo: 0,
      exibirParcelasIntermediarias: false,
    },
  ]);
  const [
    parcelasIntermediariasPorAtivo,
    setParcelasIntermediariasPorAtivo,
  ] = useState({});

  const beginPlanoFinanciamento = (parcelas, codigoAtivo, planos) => {
    const plano = planos.find(plano => plano.parcelas === parcelas);

    setPlanoFinanciamento({
      valor: plano ? plano.valor : '',
      parcelas: plano ? plano.parcelas : '',
      codigoAtivo,
      vencimento:
        ultimaParcelaEscadinhas.forEach(item => {
          if (item.codigo === codigoAtivo) return item.vencimentoParcela;
        }) || sugerirDiaVencimento().format('DD/MM/YYYY'),
    });
  };

  const beginCalcularParcelasCondicionantes = (
    codigoCondicionante,
    codigoAtivo,
    saldoAFinanciar,
    totalFinanciamento
  ) => {
    if (totalFinanciamento > 0) return;
    var parcelaIntermediaria = empreendimento.intermediarias.find(
      x => x.codigo === codigoCondicionante
    );

    var ativoSelecionado = ativosDaTabelaSelecionadaPorCodigo[codigoAtivo];
    var taxaPI = parseFloat(taxaParcelaIntermediaria) / parseFloat(100);
    var valorDaVenda = ativoSelecionado.valor_venda;
    var saldoFinRestante = saldoAFinanciar;

    //CONSTRUÇÃO
    var mesesConstrucao = parcelaIntermediaria.meses_construcao;
    var vencimentoConstrucao = moment()
      .date(20)
      .add(mesesConstrucao, 'months')
      .format('YYYY-MM-DD');

    if (parcelaIntermediaria.percentual_construcao > 0) {
      var descontoConstrucao =
        valorDaVenda * (parcelaIntermediaria.percentual_construcao / 100);
      var valorConstrucaoComJuros = calcularValorFuturo(
        descontoConstrucao,
        taxaParcelaIntermediaria,
        moment(dataVenda).toDate(),
        moment(vencimentoConstrucao).toDate()
      );
      saldoFinRestante = saldoFinRestante - descontoConstrucao;
    }

    //ADIMPLENCIA
    var mesesAdimplencia = parcelaIntermediaria.meses_adimplencia;
    var vencimentoAdimplencia = moment()
      .date(20)
      .add(mesesAdimplencia, 'months')
      .format('YYYY-MM-DD');
    if (parcelaIntermediaria.percentual_adimplencia > 0) {
      var descontoAdimplencia =
        valorDaVenda * (parcelaIntermediaria.percentual_adimplencia / 100);
      var valorAdimplenciaComJuros = calcularValorFuturo(
        descontoAdimplencia,
        taxaParcelaIntermediaria,
        moment(dataVenda).toDate(),
        moment(vencimentoAdimplencia).toDate()
      );
      saldoFinRestante = saldoFinRestante - descontoAdimplencia;
    }

    console.log(
      'CONDICIONANTES PARA O ATIVO: ' + codigoAtivo,
      ' | Taxa: ' + taxaParcelaIntermediaria,
      ' | Taxa dividida: ' + taxaPI,
      ' | valorDaVenda: ' + valorDaVenda,
      ' | saldoFinRestante: ' + saldoFinRestante,
      '>>>> CONSTRUÇÃO >>>>',
      ', descontoConstrucao: ' + descontoConstrucao,
      ', valorConstrucaoComJuros: ' + valorConstrucaoComJuros,
      ', saldoFinRestante: ' + saldoFinRestante,
      ', mesesConstrucao: ' + mesesConstrucao,
      ', vencimentoConstrucao: ' + vencimentoConstrucao,
      '>>>> ADIMPLENCIA >>>>',
      ', descontoAdimplencia: ' + descontoAdimplencia,
      ', valorAdimplenciaComJuros: ' + valorAdimplenciaComJuros,
      ', saldoFinRestante: ' + saldoFinRestante,
      ', mesesAdimplencia' + mesesAdimplencia,
      ', vencimentoAdimplencia: ' + vencimentoAdimplencia
    );

    const condicionanteAtual = {
      descricao: parcelaIntermediaria.descricao,
      codigoCondicionante,
      valorConstrucao: descontoConstrucao,
      valorConstrucaoComJuros,
      valorAdimplencia: descontoAdimplencia,
      valorAdimplenciaComJuros,
      parcelas: 1,
      vencimentoConstrucao,
      vencimentoAdimplencia,
      codigoAtivo,
      taxa: taxaPI,
      condicaoAdimplenciaAplicada: false,
      condicaoConstrucaoAplicada: false,
    };

    const index = parcelasCondicionantes.findIndex(
      item => item.codigoAtivo === codigoAtivo
    );
    if (index !== -1) {
      // Objeto já existe na lista, faz o merge dos dados
      setParcelasCondicionantes(prevParcelas => {
        const novaLista = [...prevParcelas];
        novaLista[index] = { ...novaLista[index], ...condicionanteAtual };
        return novaLista;
      });
    } else {
      // Objeto não existe na lista, adiciona na lista
      setParcelasCondicionantes(prevParcelas => [
        ...prevParcelas,
        condicionanteAtual,
      ]);
    }
  };

  const criarParcelasFinanciamento = values => {
    const { parcelas, valor, vencimento, codigoAtivo } = values;

    let data = moment(vencimento);

    if (!data.isValid()) {
      data = moment(vencimento, 'DD/MM/YYYY');
    }

    const saldoAFinanciar = calcularSaldoAFinanciar(
      ativosDaTabelaSelecionadaPorCodigo[codigoAtivo],
      ativosDaTabelaSelecionados,
      totalTitulosSinal
    );

    const condicionantesPorAtivo = obterParcelasCondicionantesPorCodigoAtivo(
      codigoAtivo
    );

    const valorConstrucao =
      condicionantesPorAtivo &&
      condicionantesPorAtivo.condicaoConstrucaoAplicada
        ? condicionantesPorAtivo.valorConstrucao
        : 0;

    const valorAdimplencia =
      condicionantesPorAtivo &&
      condicionantesPorAtivo.condicaoAdimplenciaAplicada
        ? condicionantesPorAtivo.valorAdimplencia
        : 0;

    const saldoAFinanciarRestante =
      saldoAFinanciar - (valorConstrucao + valorAdimplencia);

    const valorPresente = saldoAFinanciarRestante / parcelas;

    adicionarParcelaFinanciamento(
      codigoAtivo,
      valorPresente,
      valor,
      data,
      BOLETO,
      parcelas
    );

    setPossuiParcelasFinanciamento([
      ...possuiParcelasFinanciamento,
      codigoAtivo,
    ]);

    setPlanoFinanciamento(null);
  };

  const criarParcelasIntermediarias = (codigoAtivo, tipoConstrucao) => {
    var tipoBalao = tipoConstrucao ? 1 : 2;
    const condicionantesPorAtivo = obterParcelasCondicionantesPorCodigoAtivo(
      codigoAtivo
    );

    var valor = tipoConstrucao
      ? condicionantesPorAtivo.valorConstrucaoComJuros
      : condicionantesPorAtivo.valorAdimplenciaComJuros;

    var valorPresente = tipoConstrucao
      ? condicionantesPorAtivo.valorConstrucao
      : condicionantesPorAtivo.valorAdimplencia;

    var vencimento = tipoConstrucao
      ? condicionantesPorAtivo.vencimentoConstrucao
      : condicionantesPorAtivo.vencimentoAdimplencia;
    var parcelas = 1;

    adicionarParcelaFinanciamento(
      codigoAtivo,
      valorPresente,
      valor,
      vencimento,
      condicionantesPorAtivo.codigoCondicionante,
      parcelas,
      0, //numeroDaParcela
      false, //tipoParcelaEscadinha
      tipoBalao,
      condicionantesPorAtivo.taxa
    );

    setParcelasCondicionantes(prevParcelas => {
      return prevParcelas.map(item => {
        if (item.codigoAtivo === codigoAtivo) {
          return {
            ...item,
            condicaoAdimplenciaAplicada: !tipoConstrucao
              ? true
              : item.condicaoAdimplenciaAplicada,
            condicaoConstrucaoAplicada: tipoConstrucao
              ? true
              : item.condicaoConstrucaoAplicada,
          };
        }
        return item;
      });
    });
  };

  const limparFinanciamento = (codigoAtivo, limparProgramadas) => {
    // Limpar parcelas programadas para o lote específico
    setExibirParcelasProgramadas(prev =>
      prev.map(item =>
        item.codigo === codigoAtivo
          ? { ...item, exibirParcelasProgramadas: false }
          : item
      )
    );

    // Limpar última parcela escadinha para o lote específico
    setUltimaParcelaEscadinhas(prev =>
      prev.map(item =>
        item.codigo === codigoAtivo
          ? { ...item, vencimentoParcela: null }
          : item
      )
    );

    // Remover o lote da lista de parcelas de financiamento
    const novaLista = possuiParcelasFinanciamento.filter(
      codigo => codigo !== codigoAtivo
    );
    removerTodasParcelasFinanciamento(codigoAtivo);

    // Limpar parcelas condicionantes apenas para o lote específico
    setParcelasCondicionantes(prevParcelas =>
      prevParcelas.filter(item => item.codigoAtivo !== codigoAtivo)
    );

    // Atualizar as parcelas do financiamento existentes
    setPossuiParcelasFinanciamento(novaLista);

    // Limpar exibição das parcelas condicionantes e intermediárias
    setExibirParcelasCondicionantes(prev =>
      prev.map(item =>
        item.codigo === codigoAtivo
          ? { ...item, exibirParcelasCondicionantes: false }
          : item
      )
    );

    setExibirParcelasIntermediarias(prev =>
      prev.map(item =>
        item.codigo === codigoAtivo
          ? { ...item, exibirParcelasIntermediarias: false }
          : item
      )
    );
  };

  const ativosDaTabelaSelecionados = Object.values(
    ativosDaTabelaSelecionadaPorCodigo
  ).filter(ativo => ativosSelecionados.includes(ativo.codigo));

  const obterParcelasCondicionantesPorCodigoAtivo = codigoAtivo => {
    return parcelasCondicionantes.find(
      item => item.codigoAtivo === codigoAtivo
    );
  };

  const montarOpcoesParcelasIntemediarias = () => {
    return empreendimento.intermediarias.map(({ codigo, descricao }) => ({
      value: codigo,
      label: descricao,
    }));
  };

  const incluirParcelasIntermediarias = codigoAtivo => {
    const parcelasIntermediarias = parcelasIntermediariasPorAtivo[codigoAtivo];

    if (parcelasIntermediarias && parcelasIntermediarias.length > 0) {
      parcelasIntermediarias.forEach((parcela, index) => {
        adicionarParcelaFinanciamento(
          parcela.codigo_ativo, //codigoAtivo
          parcela.valor_presente, //valorPresente
          parcela.valor_futuro, //valor
          parcela.vencimento, // vencimento
          BOLETO, //tipo
          1, //parcelas
          index + 1, //numeroDaParcela
          null, //tipoParcelaEscadinha
          null, //tipoBalao
          null, //taxa
          true // tipoIntermediaria
        );
      });

      setParcelasIntermediariasPorAtivo(prevState => {
        const { [codigoAtivo]: _, ...rest } = prevState;
        return rest;
      });
    }
  };

  useEffect(() => {
    if (!readonly) {
      ativosSelecionados.map(codigoAtivo => {
        limparFinanciamento(codigoAtivo);
        return null;
      });
    }
    montarObjetoCondicionanteReadOnly();
    montarListaDeParcelasEmSeriesPorAtivoSelecionado();
  }, []);

  const montarObjetoCondicionanteReadOnly = () => {
    if (!readonly) return;
    ativosSelecionados.map(codigoAtivo => {
      const financiamentoAtivo =
        (financiamentos[codigoAtivo] || {}).parcelasFinanciamento || [];
      setInfoVendas(financiamentoAtivo || []);
      const parcelasIntermediarias = financiamentoAtivo.filter(
        f => f.periodicidade_balao
      );
      if (!parcelasIntermediarias.length) return null;

      const parcelaIntermediariaEncontrada = empreendimento.intermediarias.find(
        i => i.codigo === parcelasIntermediarias[0].codigo_intermediaria
      );
      if (!parcelaIntermediariaEncontrada) return null;

      let condicionantePorAtivo = {};

      parcelasIntermediarias.forEach(parcela => {
        if (parcela.periodicidade_balao === 1) {
          condicionantePorAtivo.valorConstrucao = parcela.valor_presente;
          condicionantePorAtivo.valorConstrucaoComJuros = parcela.valor;
          condicionantePorAtivo.vencimentoConstrucao = parcela.vencimento;
          condicionantePorAtivo.condicaoConstrucaoAplicada = true;
          //desativa o oposto
          condicionantePorAtivo.valorAdimplencia = '0,00';
          condicionantePorAtivo.valorAdimplenciaComJuros = '0,00';
          condicionantePorAtivo.condicaoAdimplenciaAplicada = false;
        } else {
          condicionantePorAtivo.valorAdimplencia = parcela.valor_presente;
          condicionantePorAtivo.valorAdimplenciaComJuros = parcela.valor;
          condicionantePorAtivo.vencimentoAdimplencia = parcela.vencimento;
          condicionantePorAtivo.condicaoAdimplenciaAplicada = true;
          //desativa o oposto
          condicionantePorAtivo.valorConstrucao = '0,00';
          condicionantePorAtivo.valorConstrucaoComJuros = '0,00';
          condicionantePorAtivo.condicaoConstrucaoAplicada = false;
        }
      });
      condicionantePorAtivo.descricao =
        parcelaIntermediariaEncontrada.descricao;
      condicionantePorAtivo.codigoCondicionante =
        parcelaIntermediariaEncontrada.codigo;
      condicionantePorAtivo.codigoAtivo = codigoAtivo;
      condicionantePorAtivo.parcelas = 1;

      setParcelasCondicionantes(prevParcelas => [
        ...prevParcelas,
        condicionantePorAtivo,
      ]);

      setPossuiParcelasFinanciamento([
        ...possuiParcelasFinanciamento,
        codigoAtivo,
      ]);
      return null;
    });
  };

  const ultimoVencimentoSinal = moment(
    _maxBy(listaFormasDePagamento, 'vencimento').vencimento
  );

  const sugerirDiaVencimento = () => {
    let diaVenc = ultimoVencimentoSinal.format('D');

    if (diaVenc <= 5) diaVenc = 5;
    else if (diaVenc > 5 && diaVenc <= 10) diaVenc = 10;
    else if (diaVenc > 10 && diaVenc <= 15) diaVenc = 15;
    else if (diaVenc > 15 && diaVenc <= 20) diaVenc = 20;
    else if (diaVenc > 20) diaVenc = 25;
    return moment(ultimoVencimentoSinal.add(1, 'M')).set('date', diaVenc);
  };

  const montarListaDeParcelasEmSeriesPorAtivoSelecionado = () => {
    if (readonly || taxaTabela === 0) return;

    const novasParcelasEmSeriesEscadinha = {};
    const diferencaMeses = Math.round(
      ultimoVencimentoSinal.diff(moment(), 'months', true)
    );
    ativosSelecionados.forEach(ativoSelecionado => {
      const ativoEscadinha = ativosPorCodigo[ativoSelecionado].ativo_escadinha;
      if (!ativoEscadinha) return;

      const planos =
        ativosDaTabelaSelecionadaPorCodigo[ativoSelecionado].planos;
      const maiorParcela = planos[planos.length - 1].parcelas;
      const totalParcelasProgramadas =
        ativoEscadinha.parcelas_por_serie * ativoEscadinha.quantidade_de_series;
      if (maiorParcela <= totalParcelasProgramadas) return;

      let seriesEscadinha = [];
      let vencimentoInicialSerie = sugerirDiaVencimento();
      let valorSerie = ativoEscadinha.parcela_inicial;

      for (let i = 0; i < ativoEscadinha.quantidade_de_series; i++) {
        const parcelas =
          i === 0
            ? ativoEscadinha.parcelas_por_serie - diferencaMeses
            : ativoEscadinha.parcelas_por_serie;

        seriesEscadinha.push({
          valorSerie,
          vencimento: vencimentoInicialSerie.format('YYYY-MM-DD'),
          parcelas,
        });
        valorSerie += ativoEscadinha.degrau;
        vencimentoInicialSerie.add(parcelas, 'months');
      }

      novasParcelasEmSeriesEscadinha[ativoSelecionado] = seriesEscadinha;
    });

    setParcelasEmSeriesEscadinha(prevSeries => ({
      ...prevSeries,
      ...novasParcelasEmSeriesEscadinha,
    }));
  };

  const incluirParcelasProgramadasPorAtivo = codigoAtivo => {
    const seriesDoAtivo = parcelasEmSeriesEscadinha[codigoAtivo];
    var vencimentoDaParcela;
    var numeroDaParcela = 1;
    const taxa = taxaTabela;

    seriesDoAtivo.forEach(serie => {
      vencimentoDaParcela = moment(serie.vencimento).set('date', diaVencimento);
      for (let i = 1; i <= serie.parcelas; i++) {
        const valorPresente = calcularValorPresente(
          serie.valorSerie,
          taxa,
          moment(dataVenda).toDate(),
          vencimentoDaParcela.toDate()
        );

        const vencimento = vencimentoDaParcela;
        adicionarParcelaFinanciamento(
          codigoAtivo,
          valorPresente,
          serie.valorSerie,
          vencimento.format('YYYY-MM-DD'),
          BOLETO,
          1,
          numeroDaParcela,
          true //tipoParcelaEscadinha
        );
        numeroDaParcela++;
        vencimentoDaParcela.add(1, 'months');
      }
    });

    setUltimaParcelaEscadinhas([
      {
        codigo: codigoAtivo,
        vencimentoParcela: vencimentoDaParcela.format('DD/MM/YYYY'),
      },
    ]);
  };

  const calcularSaldoAFinanciarRestante = (
    parcelasEmSerie,
    saldoAFinanciar,
    valorConstrucao,
    valorAdimplencia,
    parcelasIntermediarias
  ) => {
    const somaParcelasVPEmSerie = parcelasEmSerie.reduce(
      (acumulador, parcela) => acumulador + parcela.valor_presente,
      0
    );
    const somaParcelasIntermediarias = parcelasIntermediarias.reduce(
      (acumulador, parcela) => acumulador + parcela.valor_presente,
      0
    );

    const descontos =
      valorConstrucao + valorAdimplencia + somaParcelasIntermediarias;
    const saldoAFinanciarRestante = saldoAFinanciar - descontos;
    if (somaParcelasVPEmSerie > 0) {
      const ultimoVencimentoEmSerie =
        parcelasEmSerie[parcelasEmSerie.length - 1].vencimento;
      const saldoAFinanciarParaProjetar =
        saldoAFinanciarRestante - somaParcelasVPEmSerie;
      const saldoAFinanciarFuturo = calcularValorFuturo(
        saldoAFinanciarParaProjetar,
        taxaTabela,
        moment(dataVenda).toDate(),
        moment(ultimoVencimentoEmSerie)
          .add(1, 'days')
          .toDate()
      );

      return saldoAFinanciarFuturo;
    } else {
      return saldoAFinanciarRestante;
    }
  };

  const montarLabelGridParcela = (
    parcela,
    quantidadeParcelasFinanciamentoRegular,
    quantidadeParcelasEmSeries,
    quantidadeParcelasIntermediarias
  ) => {
    if (parcela.periodicidade_balao) return '1/1';
    else if (parcela.tipo_escadinha) {
      return `${parcela.parcela} / ${quantidadeParcelasEmSeries}`;
    } else if (parcela.tipo_intermediaria) {
      return `${parcela.parcela} / ${quantidadeParcelasIntermediarias}`;
    } else
      return `${parcela.parcela} / ${quantidadeParcelasFinanciamentoRegular}`;
  };

  const calcularSaldoRestanteExibicao = (
    saldoAFinanciar,
    parcelasFinanciamentoRegular,
    saldoAFinanciarRestante,
    parcelasEmSerie,
    valorConstrucao,
    valorAdimplencia,
    parcelasIntermediarias
  ) => {
    const somaParcelasVPFinanciamento = parcelasFinanciamentoRegular.reduce(
      (acumulador, parcela) => acumulador + parcela.valor_presente,
      0
    );
    const somaParcelasVPEmSerie = parcelasEmSerie.reduce(
      (acumulador, parcela) => acumulador + parcela.valor_presente,
      0
    );
    const somaParcelasIntermediarias = parcelasIntermediarias.reduce(
      (acumulador, parcela) => acumulador + parcela.valor_presente,
      0
    );

    console.log('<<<DADOS PARA RECALCULAR SALDO RESTANTE DE EXIBIÇÃO>>>');
    console.log('Saldo a Financiar original: ', saldoAFinanciar);
    console.log('Saldo a Financiar real', saldoAFinanciarRestante);
    console.log('PI Construcao aplicado', valorConstrucao);
    console.log('PI Adimplencia aplicado', valorAdimplencia);
    console.log('>>somaParcelasVPEmSerie:', somaParcelasVPEmSerie);
    console.log('>>somaParcelasIntermediarias:', somaParcelasIntermediarias);
    console.log('>>somaParcelasVPFinanciamento:', somaParcelasVPFinanciamento);

    const saldoRestanteExibicaoVP =
      saldoAFinanciar -
      somaParcelasVPFinanciamento -
      somaParcelasVPEmSerie -
      somaParcelasIntermediarias -
      valorConstrucao -
      valorAdimplencia;

    console.log('saldoRestanteExibicaoVP:', saldoRestanteExibicaoVP);
    if (saldoRestanteExibicaoVP <= 0) return '0,00';

    if (somaParcelasVPEmSerie > 0) {
      const ultimaParcelaEscadinha =
        parcelasEmSerie[parcelasEmSerie.length - 1].vencimento;
      const dataBase = moment(dataVenda).toDate();
      const dataVencimento = moment(ultimaParcelaEscadinha)
        .add(1, 'days')
        .toDate();

      const saldoRestanteExibicaoVF = calcularValorFuturo(
        saldoRestanteExibicaoVP,
        taxaTabela,
        dataBase,
        dataVencimento
      );
      console.log('saldoRestanteExibicaoVF:', saldoRestanteExibicaoVF);
      return saldoRestanteExibicaoVF;
    }

    return saldoRestanteExibicaoVP;
  };

  return (
    <>
      {ativosSelecionados &&
        ativosSelecionados.map(codigo => {
          const ativoDaTabela = ativosDaTabelaSelecionadaPorCodigo[codigo];
          const financiamentoAtivo =
            (financiamentos[codigo] || {}).parcelasFinanciamento || [];
          const condicionantesPorAtivo = obterParcelasCondicionantesPorCodigoAtivo(
            codigo
          );

          const totalFinanciamento = financiamentoAtivo.reduce(
            (total, parcela) => total + parcela.valor,
            0
          );

          const estadosCheckBoxes = useMemo(() => {
            const mapearEstado = (lista, chave) =>
              lista.reduce((acc, item) => {
                acc[item.codigo] = item[chave];
                return acc;
              }, {});

            return {
              condicionantes: mapearEstado(
                exibirParcelasCondicionantes,
                'exibirParcelasCondicionantes'
              ),
              programadas: mapearEstado(
                exibirParcelasProgramadas,
                'exibirParcelasProgramadas'
              ),
              intermediarias: mapearEstado(
                exibirParcelasIntermediarias,
                'exibirParcelasIntermediarias'
              ),
            };
          }, [
            exibirParcelasCondicionantes,
            exibirParcelasProgramadas,
            exibirParcelasIntermediarias,
          ]);

          const parcelasFinanciamentoRegular = financiamentoAtivo.filter(
            x =>
              !x.periodicidade_balao &&
              !x.tipo_escadinha &&
              !x.tipo_intermediaria
          );

          const bloqueiaIntermediarias =
            parcelasFinanciamentoRegular.length > 0;

          const valorConstrucao =
            condicionantesPorAtivo &&
            condicionantesPorAtivo.condicaoConstrucaoAplicada
              ? condicionantesPorAtivo.valorConstrucao
              : 0;

          const valorAdimplencia =
            condicionantesPorAtivo &&
            condicionantesPorAtivo.condicaoAdimplenciaAplicada
              ? condicionantesPorAtivo.valorAdimplencia
              : 0;

          const saldoAFinanciar = calcularSaldoAFinanciar(
            ativoDaTabela,
            ativosDaTabelaSelecionados,
            totalTitulosSinal
          );

          const parcelasEmSerie = financiamentoAtivo.filter(
            parcela => parcela.tipo_escadinha
          );

          const parcelasIntermediarias = financiamentoAtivo.filter(
            parcela => parcela.tipo_intermediaria
          );

          const saldoAFinanciarRestante = calcularSaldoAFinanciarRestante(
            parcelasEmSerie,
            saldoAFinanciar,
            valorConstrucao,
            valorAdimplencia,
            parcelasIntermediarias
          );

          const saldoRestanteExibicao = calcularSaldoRestanteExibicao(
            saldoAFinanciar,
            parcelasFinanciamentoRegular,
            saldoAFinanciarRestante,
            parcelasEmSerie,
            valorConstrucao,
            valorAdimplencia,
            parcelasIntermediarias
          );

          const planosRecalculados = reCalcularPlanosFinanciamento(
            ativoDaTabela.planos,
            saldoAFinanciarRestante,
            tabelaSelecionada
          );

          const optionsPlanos = planosRecalculados.map(
            ({ parcelas, valor }) => ({
              value: parcelas,
              label: `${parcelas} parcelas de ${formatarMonetario(valor)}`,
            })
          );

          optionsPlanos.push({
            value: 0,
            label: 'Plano específico',
          });

          const optionsIntermediarias = montarOpcoesParcelasIntemediarias();

          const condicaoAplicada =
            condicionantesPorAtivo &&
            (condicionantesPorAtivo.condicaoConstrucaoAplicada ||
              condicionantesPorAtivo.condicaoAdimplenciaAplicada);

          const handleExibirCondicionantes = (codigo, e) => {
            const isChecked = e.target.checked;

            setExibirParcelasCondicionantes(prevState => {
              const existe = prevState.find(item => item.codigo === codigo);

              if (existe) {
                return prevState.map(item =>
                  item.codigo === codigo
                    ? { ...item, exibirParcelasCondicionantes: isChecked }
                    : item
                );
              }

              return [
                ...prevState,
                { codigo, exibirParcelasCondicionantes: isChecked },
              ];
            });
          };
          const handleExibirProgramadas = (codigo, e) => {
            const isChecked = e.target.checked;

            setExibirParcelasProgramadas(prevState => {
              const existe = prevState.find(item => item.codigo === codigo);

              if (existe) {
                return prevState.map(item =>
                  item.codigo === codigo
                    ? { ...item, exibirParcelasProgramadas: isChecked }
                    : item
                );
              }

              return [
                ...prevState,
                { codigo, exibirParcelasProgramadas: isChecked },
              ];
            });
          };
          const handleExibirIntermediarias = (codigo, e) => {
            const isChecked = e.target.checked;

            setExibirParcelasIntermediarias(prevState => {
              const existe = prevState.find(item => item.codigo === codigo);

              if (existe) {
                return prevState.map(item =>
                  item.codigo === codigo
                    ? { ...item, exibirParcelasIntermediarias: isChecked }
                    : item
                );
              }

              return [
                ...prevState,
                { codigo, exibirParcelasIntermediarias: isChecked },
              ];
            });
          };

          return (
            <Formik
              key={codigo}
              initialValues={{
                parcelas: 1,
                valor: '',
                vencimento_intermediarias: '',
              }}
            >
              {({ values }) => (
                <Form key={codigo}>
                  <Card className="border-0 mt-3" key="index">
                    <Card.Body className="p-2">
                      <div className="d-flex justify-content-between">
                        <Col md="6 my-auto">
                          <span>
                            Quadra{' '}
                            <strong className="text-dark">
                              {ativosPorCodigo[codigo].quadra}
                            </strong>{' '}
                            / Lote{' '}
                            <strong className="text-dark">
                              {ativosPorCodigo[codigo].lote}
                            </strong>
                          </span>
                        </Col>
                        <Col md="6">
                          <div className="row align-items-center justify-content-end">
                            <span>Vencimento</span>
                            <Selector
                              className="col-md-8"
                              options={[
                                { value: 5, label: 'Dia 5' },
                                { value: 10, label: 'Dia 10' },
                                { value: 15, label: 'Dia 15' },
                                { value: 20, label: 'Dia 20' },
                                { value: 25, label: 'Dia 25' },
                              ]}
                              placeholder="Vencimento..."
                              defaultValue={{
                                label: 'Dia 10',
                                value: 10,
                              }}
                              onChange={option => {
                                setDiaVencimento(option.value);
                              }}
                              // isDisabled={exibirFinanciamento}
                            />
                          </div>
                        </Col>
                      </div>
                      <hr />

                      {empreendimento.intermediarias.length > 0 && (
                        <div>
                          <div className="custom-control custom-checkbox z-index-0">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`chkExibirParcelasCondicionantes${codigo}`}
                              name={`chkExibirParcelasCondicionantes${codigo}`}
                              onChange={e =>
                                handleExibirCondicionantes(codigo, e)
                              }
                              defaultChecked={
                                estadosCheckBoxes.condicionantes[codigo] ||
                                false
                              }
                              disabled={
                                bloqueiaIntermediarias ||
                                estadosCheckBoxes.programadas[codigo] ||
                                estadosCheckBoxes.intermediarias[codigo]
                              }
                              checked={
                                estadosCheckBoxes.condicionantes[codigo] ||
                                false
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`chkExibirParcelasCondicionantes${codigo}`}
                            >
                              <strong>Parcelas condicionantes</strong>
                            </label>
                          </div>
                          {estadosCheckBoxes.condicionantes[codigo] && (
                            <>
                              <Row className="mt-1">
                                <Col md="12">
                                  <small>Condições</small>
                                  {possuiParcelasFinanciamento.includes(
                                    codigo
                                  ) || condicaoAplicada ? (
                                    <span className="d-block">
                                      {condicionantesPorAtivo
                                        ? condicionantesPorAtivo.descricao
                                        : 'NENHUMA CONDICIONANTE INFORMADA'}
                                    </span>
                                  ) : (
                                    <Selector
                                      noOptionsMessage={() =>
                                        'Nenhuma condicionante encontrada'
                                      }
                                      placeholder="Condicionantes..."
                                      className="mb-2"
                                      options={optionsIntermediarias}
                                      onChange={option => {
                                        beginCalcularParcelasCondicionantes(
                                          option.value,
                                          codigo,
                                          saldoAFinanciar,
                                          totalFinanciamento
                                        );
                                      }}
                                    />
                                  )}
                                </Col>
                                <Row></Row>
                              </Row>
                              {condicionantesPorAtivo && (
                                <Row>
                                  {condicionantesPorAtivo.valorAdimplencia >
                                    0 && (
                                    <Col md="6" className="pr-0">
                                      <div className="border p-3">
                                        <button
                                          type="button"
                                          className={`btn btn-sm btn-outline-${
                                            condicionantesPorAtivo.condicaoAdimplenciaAplicada
                                              ? 'success'
                                              : 'primary'
                                          } text-uppercase w-100`}
                                          onClick={() =>
                                            criarParcelasIntermediarias(
                                              codigo,
                                              false
                                            )
                                          }
                                          disabled={
                                            readonly ||
                                            estadosCheckBoxes.programadas[
                                              codigo
                                            ] ||
                                            estadosCheckBoxes.intermediarias[
                                              codigo
                                            ] ||
                                            condicionantesPorAtivo.condicaoAdimplenciaAplicada ||
                                            possuiParcelasFinanciamento.includes(
                                              codigo
                                            )
                                          }
                                        >
                                          {condicionantesPorAtivo.condicaoAdimplenciaAplicada ? (
                                            <>
                                              <FaCheck className="mr-2" />
                                            </>
                                          ) : (
                                            <>
                                              <FaPlus className="mr-2" />
                                            </>
                                          )}
                                          Adimplência
                                        </button>

                                        <p className="my-1 text-success">
                                          <small className="d-block">
                                            Valor descontado
                                          </small>
                                          <strong>
                                            {formatarMonetario(
                                              condicionantesPorAtivo.valorAdimplencia
                                            )}
                                          </strong>
                                        </p>
                                        <div className="text-warning d-flex justify-content-between">
                                          <div>
                                            <small className="d-block">
                                              Valor projetado
                                            </small>
                                            {formatarMonetario(
                                              condicionantesPorAtivo.valorAdimplenciaComJuros
                                            )}
                                          </div>
                                          <div>
                                            <small className="d-block">
                                              Vencimento
                                            </small>
                                            {format.date(
                                              condicionantesPorAtivo.vencimentoAdimplencia
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                  {condicionantesPorAtivo.valorConstrucao >
                                    0 && (
                                    <Col md="6" className="pl-0">
                                      <div className="border p-3">
                                        <button
                                          type="button"
                                          className={`btn btn-sm btn-outline-${
                                            condicionantesPorAtivo.condicaoConstrucaoAplicada
                                              ? 'success'
                                              : 'primary'
                                          } text-uppercase w-100`}
                                          onClick={() =>
                                            criarParcelasIntermediarias(
                                              codigo,
                                              true
                                            )
                                          }
                                          disabled={
                                            readonly ||
                                            estadosCheckBoxes.programadas[
                                              codigo
                                            ] ||
                                            estadosCheckBoxes.intermediarias[
                                              codigo
                                            ] ||
                                            condicionantesPorAtivo.condicaoConstrucaoAplicada ||
                                            possuiParcelasFinanciamento.includes(
                                              codigo
                                            )
                                          }
                                        >
                                          {condicionantesPorAtivo.condicaoConstrucaoAplicada ? (
                                            <>
                                              <FaCheck className="mr-2" />
                                            </>
                                          ) : (
                                            <>
                                              <FaPlus className="mr-2" />
                                            </>
                                          )}
                                          Construção
                                        </button>
                                        <p className="my-1 text-success">
                                          <small className="d-block">
                                            Valor descontado
                                          </small>
                                          <strong>
                                            {formatarMonetario(
                                              condicionantesPorAtivo.valorConstrucao
                                            )}
                                          </strong>
                                        </p>

                                        <div className="text-warning d-flex justify-content-between">
                                          <div>
                                            <small className="d-block">
                                              Valor projetado
                                            </small>
                                            {formatarMonetario(
                                              condicionantesPorAtivo.valorConstrucaoComJuros
                                            )}
                                          </div>
                                          <div>
                                            <small className="d-block">
                                              Vencimento
                                            </small>
                                            {format.date(
                                              condicionantesPorAtivo.vencimentoConstrucao
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                              )}
                            </>
                          )}
                          <hr className="mt-2" />
                        </div>
                      )}

                      {!readonly && parcelasEmSeriesEscadinha[codigo] && (
                        <div>
                          <div className="custom-control custom-checkbox z-index-0">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`chkExibirParcelasProgramadas${codigo}`}
                              name={`chkExibirParcelasProgramadas${codigo}`}
                              onChange={e => {
                                handleExibirProgramadas(codigo, e);

                                if (e.target.checked) {
                                  incluirParcelasProgramadasPorAtivo(codigo);
                                } else {
                                  limparFinanciamento(codigo, true);
                                }
                              }}
                              defaultChecked={
                                estadosCheckBoxes.programadas[codigo] || false
                              }
                              disabled={
                                bloqueiaIntermediarias ||
                                estadosCheckBoxes.intermediarias[codigo]
                              }
                              checked={estadosCheckBoxes.programadas[codigo]}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`chkExibirParcelasProgramadas${codigo}`}
                            >
                              <strong>Parcelas programadas</strong>
                            </label>
                          </div>

                          {estadosCheckBoxes.programadas[codigo] && (
                            <Row className="mt-1">
                              {ultimaParcelaEscadinhas.some(
                                parcela =>
                                  parcela.codigo === codigo &&
                                  parcela.vencimentoParcela
                              ) && (
                                <Col md="12">
                                  <p className="text-center text-success">
                                    O cliente optou em utilizar as parcelas
                                    programadas
                                  </p>
                                </Col>
                              )}
                            </Row>
                          )}
                          <hr className="mt-2" />
                        </div>
                      )}

                      {empreendimento.habilita_intermediarias && (
                        <>
                          <div>
                            <div className="custom-control custom-checkbox z-index-0">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`chkExibirParcelasIntermediarias${codigo}`}
                                name={`chkExibirParcelasIntermediarias${codigo}`}
                                onChange={e =>
                                  handleExibirIntermediarias(codigo, e)
                                }
                                defaultChecked={
                                  estadosCheckBoxes.intermediarias[codigo]
                                }
                                disabled={bloqueiaIntermediarias}
                                checked={
                                  estadosCheckBoxes.intermediarias[codigo]
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`chkExibirParcelasIntermediarias${codigo}`}
                              >
                                <strong>Parcelas intermediárias</strong>
                              </label>
                            </div>
                            {estadosCheckBoxes.intermediarias[codigo] && (
                              <ParcelasIntermediarias
                                codigo={codigo}
                                diaVencimento={diaVencimento}
                                taxaTabela={taxaTabela}
                                dataVenda={dataVenda}
                                valoresCalculados={
                                  parcelasIntermediariasPorAtivo[codigo] || []
                                }
                                setValoresCalculados={novosValores => {
                                  setParcelasIntermediariasPorAtivo(
                                    prevState => ({
                                      ...prevState,
                                      [codigo]: novosValores,
                                    })
                                  );
                                }}
                                incluirParcelas={incluirParcelasIntermediarias}
                                exibirParcelasIntermediarias={exibirParcelasIntermediarias.find(
                                  item => item.codigo === codigo
                                )}
                                saldoFinal={saldoAFinanciarRestante}
                              />
                            )}
                            <hr className="mt-2" />
                          </div>
                        </>
                      )}

                      <Row>
                        <Col>
                          <strong className="d-block">
                            Parcelas do financiamento
                          </strong>
                          <Row className="my-2 align-items-center">
                            <Col md="4">
                              <p className="m-0">
                                <small className="d-block">
                                  Saldo a financiar
                                </small>
                                {formatarMonetario(saldoAFinanciar)}
                              </p>
                            </Col>
                            <Col md="4">
                              <p className="m-0">
                                <small className="d-block">
                                  Saldo restante
                                </small>
                                {formatarMonetario(saldoRestanteExibicao)}
                              </p>
                            </Col>
                            <Col md="4">
                              <p className="m-0">
                                <small className="d-block">
                                  Valor total do financiamento
                                </small>
                                {formatarMonetario(totalFinanciamento, false)}
                              </p>
                            </Col>
                          </Row>

                          {!readonly &&
                            parcelasFinanciamentoRegular.length === 0 && (
                              <>
                                <small className="mt-2">
                                  Formas de pagamentos
                                </small>
                                <Selector
                                  noOptionsMessage={() =>
                                    'Nenhum plano encontrado'
                                  }
                                  placeholder="Selecione um plano..."
                                  className="mb-2 z-3"
                                  options={optionsPlanos}
                                  onChange={option => {
                                    beginPlanoFinanciamento(
                                      option.value,
                                      codigo,
                                      planosRecalculados
                                    );
                                  }}
                                />
                              </>
                            )}

                          {planoFinanciamento &&
                            planoFinanciamento.codigoAtivo === codigo && (
                              <FormPlanoFinanciamento
                                onCancel={() => setPlanoFinanciamento(null)}
                                onConfirm={criarParcelasFinanciamento}
                                initialValues={planoFinanciamento}
                                parcelasAtuais={parcelasFinanciamentoRegular}
                                saldoAFinanciar={saldoAFinanciarRestante}
                                tabela={tabelaSelecionada}
                                vencimentoPlanejado={sugerirDiaVencimento().format(
                                  'DD/MM/YYYY'
                                )}
                                vencimentoObrigatorioEscadinha={
                                  (
                                    ultimaParcelaEscadinhas.find(
                                      item => item.codigo === codigo
                                    ) || {}
                                  ).vencimentoParcela
                                }
                              />
                            )}
                        </Col>
                      </Row>

                      {financiamentoAtivo.length > 0 && (
                        <>
                          <Row>
                            {!readonly && (
                              <Col className="ml-lg-auto d-flex justify-content-end">
                                <ConfirmButton
                                  variant="outline-danger"
                                  confirmTitle="Remover tudo?"
                                  confirmText="Deseja remover todas as parcelas do financiamento?"
                                  onConfirm={() => {
                                    limparFinanciamento(codigo);
                                  }}
                                  className="btn-sm d-flex align-items-center text-uppercase mr-2"
                                >
                                  <FaTrash className="mr-2" />
                                  Limpar
                                </ConfirmButton>
                              </Col>
                            )}
                          </Row>
                          <hr />
                          <Row>
                            <Col className="my-auto">
                              <strong>Resumo das parcelas</strong>
                              <PerfectScrollbar
                                style={{ maxHeight: '200px' }}
                                className="border"
                              >
                                <Table className="table table-striped table-sm table-bordeless">
                                  <Thead>
                                    <Tr>
                                      <Th>Parcela</Th>
                                      <Th>Tipo</Th>
                                      <Th>Vencimento</Th>
                                      <Th colSpan={2}>Valor</Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                    {financiamentoAtivo.map((item, index) => (
                                      <Tr key={index}>
                                        <Td>
                                          {montarLabelGridParcela(
                                            item,
                                            parcelasFinanciamentoRegular.length,
                                            parcelasEmSerie.length,
                                            parcelasIntermediarias.length
                                          )}
                                        </Td>
                                        <Td>
                                          {item.periodicidade_balao ||
                                          item.tipo_intermediaria
                                            ? 'Balão'
                                            : 'Financ.'}
                                        </Td>
                                        <Td>{format.date(item.vencimento)}</Td>
                                        <Td>{formatarMonetario(item.valor)}</Td>
                                      </Tr>
                                    ))}
                                  </Tbody>
                                </Table>
                              </PerfectScrollbar>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Card.Body>
                  </Card>
                </Form>
              )}
            </Formik>
          );
        })}
    </>
  );
}

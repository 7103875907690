import React, { useEffect, useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { ReactComponent as Mapa } from '../../Images/Mapa/mapa_modificado.svg';
import {
  DATETIME_DEFAULT_SHORT_FORMAT,
  EMPREENDIMENTO_LANCAMENTO,
  StatusImovel,
} from '~/constant';
import ModalReserva from './ModalReserva';
import { MdCancel, MdRefresh, MdZoomIn, MdZoomOut } from 'react-icons/md';
import { formatarMonetario, format } from '~/Utils/Helpers';
import { Col, Row } from 'react-bootstrap';
import AuthStorage from '~/Utils/AuthStorage';

const MapaInterativo = ({
  ativos,
  atualizarMapa,
  atualizarReservados,
  ativosFiltrados,
  setAtivosFiltrados,
}) => {
  const [zoomQuadra, setZoomQuadra] = useState(null);
  const [mostrarModal, setMostrarModal] = useState(false);
  const [codigoAtivo, setCodigoAtivo] = useState(null);
  const [tooltipInfo, setTooltipInfo] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [ativoSelecionado, setAtivoSelecionado] = useState(null);

  const temCaracteristica = AuthStorage.temCaracteristica(453);

  const handleElementClick = (zoomToElement, elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      zoomToElement(element);
    }
  };

  const handleReset = resetTransform => {
    resetTransform();
    atualizarMapa();
    setZoomQuadra(null);
  };

  const handleAbrirModalDetalhesLote = (event, resetTransform) => {
    const targetId = event.target.id;

    setCodigoAtivo(targetId);
    setMostrarModal(true);
    setAtivosFiltrados([]);
    const ativo = ativos.find(imovel => {
      return imovel.codigo == targetId;
    });
    setAtivoSelecionado(ativo);

    handleReset(resetTransform);
  };

  useEffect(() => {
    ativos.forEach(imovel => {
      const loteElement = document.getElementById(imovel.codigo);
      if (loteElement) {
        loteElement.classList.remove(
          'disponivelF1',
          'disponivelF2',
          'vendido',
          'reservado',
          'bloqueado',
          'destacar-ativos-filtro'
        );

        // Aplica a classe correspondente ao status do imóvel
        if (
          imovel.status === StatusImovel.Vendido ||
          imovel.status === StatusImovel.PreVenda
        )
          loteElement.classList.add('vendido');
        else if (imovel.status === StatusImovel.Reservado)
          loteElement.classList.add('reservado');
        else if (imovel.status === StatusImovel.Bloqueado)
          loteElement.classList.add('bloqueado');
        else if (imovel.status === StatusImovel.Disponivel) {
          if (imovel.codigo_familia === 11452)
            loteElement.classList.add('disponivelF1');
          else if (imovel.codigo_familia === 11453)
            loteElement.classList.add('disponivelF2');
        }

        if (
          ativosFiltrados.some(filtrado => filtrado.codigo === imovel.codigo)
        ) {
          loteElement.classList.add('destacar-ativos-filtro');
        }
      }
    });
  }, [ativos, ativosFiltrados]);

  useEffect(() => {
    ativos.forEach(imovel => {
      const loteElement = document.getElementById(imovel.codigo);
      if (loteElement) {
        loteElement.addEventListener('mouseenter', event =>
          showTooltip(event, imovel)
        );
        loteElement.addEventListener('mouseleave', hideTooltip);
      }
    });

    return () => {
      ativos.forEach(imovel => {
        const loteElement = document.getElementById(imovel.codigo);
        if (loteElement) {
          loteElement.removeEventListener('mouseenter', showTooltip);
          loteElement.removeEventListener('mouseleave', hideTooltip);
        }
      });
    };
  }, [ativos]);

  const showTooltip = (event, imovel) => {
    setTooltipInfo(imovel);
    setTooltipPosition({ x: event.pageX, y: event.pageY });
  };

  const hideTooltip = () => {
    setTooltipInfo(null);
  };

  const obterStatusDoAtivo = (status, familia) => {
    const statusStyles = {
      [StatusImovel.Disponivel]: {
        text: 'Disponível',
        style: {
          borderRadius: '8px',
          padding: '1px 6px',
          background:
            familia === EMPREENDIMENTO_LANCAMENTO ? '#1C9BFF' : '#E97500',
          color: '#fff',
        },
      },
      [StatusImovel.Bloqueado]: {
        text: 'Bloqueado',
        style: {
          borderRadius: '8px',
          padding: '1px 6px',
          background: 'black',
          color: '#fff',
        },
      },
      [StatusImovel.Vendido]: {
        text: 'Vendido',
        style: {
          borderRadius: '8px',
          padding: '1px 6px',
          background: '#FF0000',
          color: '#fff',
        },
      },
      [StatusImovel.PreVenda]: {
        text: 'Vendido',
        style: {
          borderRadius: '8px',
          padding: '1px 6px',
          background: '#FF0000',
          color: '#fff',
        },
      },
      [StatusImovel.PropostaRapida]: {
        text: 'Vendido',
        style: {
          borderRadius: '8px',
          padding: '1px 6px',
          background: '#FF0000',
          color: '#fff',
        },
      },
      [StatusImovel.Reservado]: {
        text: 'Reservado',
        style: {
          borderRadius: '8px',
          padding: '1px 6px',
          background: '#FFDC15',
          color: '#fff',
        },
      },
    };

    const currentStatus = statusStyles[status];

    if (currentStatus) {
      return (
        <>
          Situação:{' '}
          <strong style={currentStatus.style}>{currentStatus.text}</strong>
        </>
      );
    }
    return null;
  };

  return (
    <div className="" style={{ position: 'relative' }}>
      <TransformWrapper>
        {({ zoomIn, zoomOut, zoomToElement, resetTransform }) => (
          <div className="w-100 h-100">
            <div className="grupo-botoes-acoes-mapa">
              <button
                className="btn btn-secondary btn-acao-mapa"
                onClick={() => handleReset(resetTransform)}
              >
                <MdCancel />
              </button>
              <button
                className="btn btn-secondary btn-acao-mapa"
                onClick={() => zoomIn()}
              >
                <MdZoomIn />
              </button>
              <button
                className="btn btn-secondary btn-acao-mapa"
                onClick={() => {
                  atualizarMapa();
                  zoomOut();
                }}
              >
                <MdZoomOut />
              </button>
              <button
                className="btn btn-success btn-acao-mapa"
                onClick={() => {
                  window.location.reload();
                }}
              >
                <MdRefresh />
              </button>
            </div>

            <TransformComponent>
              <Mapa
                className="mapa-svg"
                style={{ background: '#353535' }}
                onClick={event => {
                  const parentElement = event.target.parentElement.id;
                  const isLote = event.target.closest('#LOTES');
                  if (isLote && parentElement && !zoomQuadra) {
                    handleElementClick(zoomToElement, parentElement);
                    setZoomQuadra(parentElement);
                  }
                }}
                onDoubleClick={event => {
                  const targetId = event.target.id;
                  const isLote = event.target.closest('#LOTES');
                  if (isLote && targetId) {
                    handleAbrirModalDetalhesLote(event, resetTransform);
                  }
                }}
              />
              {ativoSelecionado && (
                <ModalReserva
                  mostrarModal={mostrarModal}
                  setMostrarModal={setMostrarModal}
                  codigoAtivo={codigoAtivo}
                  ativo={ativoSelecionado}
                  atualizarReservados={atualizarReservados}
                  atualizarMapa={atualizarMapa}
                />
              )}
            </TransformComponent>
          </div>
        )}
      </TransformWrapper>

      {tooltipInfo && (
        <div
          style={{
            position: 'absolute',
            top: tooltipPosition.y - 50,
            left: tooltipPosition.x - 50,
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '8px',
            pointerEvents: 'none',
            zIndex: 1000,
            width: '430px',
            padding: '10px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          }}
        >
          <h6 className="text-center mb-2">Informações do terreno</h6>
          <Row>
            <Col md="6">
              <small
                style={{
                  borderRadius: '8px',
                  padding: '2px 6px',
                  background:
                    tooltipInfo.codigo_familia === EMPREENDIMENTO_LANCAMENTO
                      ? '#1C98FC'
                      : '#EE7219',
                  color: '#fff',
                  display: 'inline-block',
                }}
              >
                <strong>
                  SetValley {tooltipInfo.codigo_familia === 11452 ? '1' : '2'}
                </strong>
              </small>
            </Col>
            <Col md="6">
              <small>
                Quadra: <strong>{tooltipInfo.quadra}</strong> / Lote:{' '}
                <strong>{tooltipInfo.lote}</strong>
              </small>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <small>
                {obterStatusDoAtivo(
                  tooltipInfo.status,
                  tooltipInfo.codigo_familia
                )}
              </small>
            </Col>
            <Col md="6">
              <small>
                Entrada:{' '}
                <strong>{formatarMonetario(tooltipInfo.valor_sinal)}</strong>
              </small>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <small>
                Área: <strong>{tooltipInfo.area}m²</strong>
              </small>
            </Col>
            <Col md="6">
              {tooltipInfo.valor_inicial_parcela_programada > 0 && (
                <small>
                  Programadas:{' '}
                  <strong>
                    {formatarMonetario(
                      tooltipInfo.valor_inicial_parcela_programada
                    )}
                  </strong>
                </small>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <small>
                Valor à vista:{' '}
                <strong>{formatarMonetario(tooltipInfo.valor_vista)}</strong>
              </small>
            </Col>
            <Col md="6">
              <small>
                Financ.:{' '}
                <strong>
                  {tooltipInfo.parcelas} x{' '}
                  {formatarMonetario(tooltipInfo.valor_parcela)}
                </strong>
              </small>
            </Col>
          </Row>
          {tooltipInfo.solicitacao_estorno > 0 && (
            <Row>
              <Col md="12 text-center">
                <div className="badge badge-warning w-100">
                  Aguardando estorno de proposta.
                </div>
              </Col>
            </Row>
          )}
          {tooltipInfo.observacao_reserva && temCaracteristica && (
            <Row>
              <Col md="6">
                <div className="badge badge-dark" style={{ color: 'white' }}>
                  Observação: {tooltipInfo.observacao_reserva}
                </div>
              </Col>
            </Row>
          )}

          {tooltipInfo.status !== StatusImovel.Bloqueado &&
            tooltipInfo.status !== StatusImovel.Disponivel && (
              <>
                <hr className="mb-2" />
                <h6 className="text-center mb-2">Informações da reserva</h6>
                {tooltipInfo.mesa && (
                  <Row className="mt-2">
                    <Col>
                      <small>
                        Mesa:{' '}
                        <strong>
                          {tooltipInfo.mesa} - {tooltipInfo.nome_usuario_mesa}
                        </strong>
                      </small>
                    </Col>
                  </Row>
                )}
                {tooltipInfo.nome_corretor && (
                  <Row>
                    <Col>
                      <small>
                        Corretor:{' '}
                        <strong>
                          {tooltipInfo.codigo_corretor} -{' '}
                          {tooltipInfo.nome_corretor}
                        </strong>
                      </small>
                    </Col>
                  </Row>
                )}
                {tooltipInfo.nome_cliente && (
                  <Row>
                    <Col>
                      <small>
                        Nome:{' '}
                        <strong>
                          {tooltipInfo.codigo_prospecto} -{' '}
                          {tooltipInfo.nome_cliente}
                        </strong>
                      </small>
                    </Col>
                  </Row>
                )}
                {tooltipInfo.data_validade && (
                  <Row>
                    <Col md="6">
                      <small>
                        Reserva:{' '}
                        <strong>
                          {format.date(
                            tooltipInfo.data_reserva,
                            DATETIME_DEFAULT_SHORT_FORMAT
                          )}
                        </strong>
                      </small>
                    </Col>
                    <Col md="6">
                      <small>
                        Validade:{' '}
                        <strong>
                          {format.date(
                            tooltipInfo.data_validade,
                            DATETIME_DEFAULT_SHORT_FORMAT
                          )}
                        </strong>
                      </small>
                    </Col>
                  </Row>
                )}
              </>
            )}
        </div>
      )}
    </div>
  );
};

export default MapaInterativo;

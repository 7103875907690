import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { urlAdmVendas } from '~/constant';
import { URLV1 } from '~/Components/Navbar/links';

const VendaGravada = ({ vendaGravada }) => {
  // const [segundos, setSegundos] = useState(10);

  // useEffect(() => {
  //   let countdown = setInterval(() => {
  //     if (segundos > 0) {
  //       setSegundos(segundos - 1);
  //     }
  //   }, 1000);
  //   return () => {
  //     clearInterval(countdown);
  //     if (segundos === 1) window.location = urlAdmVendas;
  //   };
  // }, [segundos]);

  const color = vendaGravada.efetivada ? '#28A745' : '#FFC107';
  return (
    <div className="text-center">
      <FaCheckCircle color="#28A745" fontSize={72} />
      <h4>
        Venda{' '}
        <span style={{ color: color }}>
          {vendaGravada.efetivada ? 'Realizada' : 'Gravada para Aprovação'}
        </span>
        !
      </h4>
      <div className="row">
        <div className="col-md-12">
          <h4>Proposta: {vendaGravada.codigo_proposta}</h4>
          {vendaGravada.codigos_pre_vendas.map(codigo => (
            <div className="text-center d-block">
              <a
                className="btn btn-outline-primary"
                target="blank"
                href={`${URLV1}/Comercial/PreVendaPropostaRapida.aspx?PreVenda=${codigo}`}
              >
                Ver proposta da Pré-venda - {codigo}
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-6">
          <a
            className="btn btn-outline-primary mr-3"
            variant="link"
            target="blank"
            onClick={() => (window.location = urlAdmVendas)}
          >
            Administração de vendas
          </a>
        </div>
        <div className="col-md-6">
          <a
            target="blank"
            className="btn btn-outline-primary ml-3"
            variant="link"
            href="/mapa"
          >
            Mapa do empreendimento
          </a>
        </div>
      </div>
    </div>
  );
};

export default VendaGravada;

import React from 'react';
import { Navbar, Container } from 'react-bootstrap';

export default function Header({ children, title }) {
  return (
    <Navbar className="bg-white shadow-bottom" expand="lg">
      <div className="container-fluid">
        <h5 className="mr-auto navbar-text mb-0 text-body">{title}</h5>
        <div className="ml-auto">{children}</div>
      </div>
    </Navbar>
  );
}

import React, { useEffect, useState } from 'react';
import qs from 'qs';

import * as api from '../../Services/Service';
import { format, formatarComZero } from '../../Utils/Helpers';
import * as broadcast from '../../Utils/Broadcast';
import { Button } from 'react-bootstrap';

const DEPARTAMENTOS = {
  13: { rotulo: 'Diretoria', cor: '#00A19B', ordem: 3, url: 'Diretoria' },
  12: { rotulo: 'Suporte - TI', cor: '#1671B8', ordem: 2, url: 'TI' },
  15: { rotulo: 'Financeiro', cor: '#94C11E', ordem: 5, url: 'Financeiro' },
  16: { rotulo: 'Jurídico', cor: '#F39300', ordem: 4, url: 'Juridico' },
  19: { rotulo: 'Apoio', cor: '#bfbfbf', ordem: 1, url: 'Apoio' },
};

const NotificacaoLancamento = ({ location }) => {
  const [solicitacoes, setSolicitacoes] = useState({});
  const [departamentoFiltrado, setDepartamentoFiltrado] = useState(null);
  const { env, departamento } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      setDepartamentoFiltrado(departamento); // Armazenar o departamento da query string

      const response = await api.consultarSolicitacoesPendentes(env);
      console.log('departamento', departamento);

      const solicitacoesAgrupadas = response
        .filter(
          item => !departamento || String(item.departamento) === departamento
        ) // Filtrar por departamento, se presente
        .sort((a, b) => new Date(a.Data) - new Date(b.Data))
        .reduce((obj, item) => {
          if (!obj[item.departamento]) {
            obj[item.departamento] = [];
          }
          obj[item.departamento].push(item);
          return obj;
        }, {});

      setSolicitacoes(solicitacoesAgrupadas);

      broadcast.connect();
      let channel = 'painel-solicitacoes';
      if (env) channel += `-${env}`;

      broadcast.addListerner(
        channel,
        'adicionar-solicitacao',
        adicionarSolicitacao
      );
      broadcast.addListerner(
        channel,
        'remover-solicitacao',
        removerSolicitacao
      );
    };

    fetchData();
  }, [location.search]);

  const adicionarSolicitacao = solicitacao => {
    setSolicitacoes(prevSolicitacoes => {
      const updated = { ...prevSolicitacoes };
      if (!updated[solicitacao.departamento]) {
        updated[solicitacao.departamento] = [];
      }
      updated[solicitacao.departamento].push(solicitacao);
      return updated;
    });
  };

  const removerSolicitacao = solicitacao => {
    setSolicitacoes(prevSolicitacoes => {
      const updated = { ...prevSolicitacoes };
      if (updated[solicitacao.departamento]) {
        updated[solicitacao.departamento] = updated[
          solicitacao.departamento
        ].filter(item => item.codigo !== solicitacao.codigo);
      }
      return updated;
    });
  };
  const handleClickCancelar = async mesa => {
    await api.NotificarDepartamentoPorMesa(
      DEPARTAMENTOS[departamento].url,
      mesa
    );
  };
  const departamentosOrdenados = Object.keys(DEPARTAMENTOS).sort(
    (a, b) => DEPARTAMENTOS[a].ordem - DEPARTAMENTOS[b].ordem
  );

  return (
    <div className="container-fluid LancamentoSolicitacoes">
      <div className="row">
        {departamentosOrdenados.map((item, index) => {
          if (departamentoFiltrado && String(item) !== departamentoFiltrado) {
            return null;
          }

          return (
            <div
              className="col h-100"
              key={index}
              style={{ backgroundColor: DEPARTAMENTOS[item].cor }}
            >
              <h3 style={{ color: '#fff' }}>{DEPARTAMENTOS[item].rotulo}</h3>
              {solicitacoes[item] && (
                <ul className="solicitacao-list">
                  {solicitacoes[item].map((solicitacao, itemindex) => (
                    <li className="solicitacao flex-column" key={itemindex}>
                      <div className="solicitacao__row solicitacao__mesa_descricao">
                        <div className="solicitacao__mesa solicitacao__col">
                          <strong>
                            {formatarComZero(solicitacao.mesa.numero)}
                          </strong>
                        </div>
                        <div className="solicitacao__col solicitacao__descricao">
                          <div className="solicitacao__row">
                            <div className="solicitacao__nome">
                              {solicitacao.mesa.nome_colaborador
                                .split(/(\s).+\s/)
                                .join('')}
                            </div>
                          </div>
                          <div className="solicitacao__row solicitacao__data">
                            <strong>
                              {format.date(solicitacao.data_inicio, 'HH:mm')}
                            </strong>
                          </div>
                        </div>
                      </div>

                      <div className="solicitacao__row solicitacao__mesa_descricao justify-content-center">
                        <Button
                          onClick={() => {
                            handleClickCancelar(solicitacao.mesa.numero);
                          }}
                          variant="danger"
                        >
                          Encerrar atendimento
                        </Button>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NotificacaoLancamento;

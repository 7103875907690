import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Container } from 'react-bootstrap';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import './ModalFiltro.css';

const ModalFiltro = ({ show, onHide, ativos, onApplyFilters }) => {
    const [ativosOriginais] = useState(ativos);
    const [ativosFiltrados, setAtivosFiltrados] = useState([]);
    const [ranges, setRanges] = useState({
        valor_vista: { min: 0, max: 1 },
        valor_inicial_parcela_programada: { min: 0, max: 1 },
        area: { min: 0, max: 1 },
    });
    const [filtros, setFiltros] = useState({
        valor_vista: { min: 0, max: 1 },
        valor_inicial_parcela_programada: { min: 0, max: 1 },
        area: { min: 0, max: 1 },
    });

    // Define os valores iniciais e os ranges
    useEffect(() => {
        setAtivosFiltrados([]);
        if (ativosOriginais && ativosOriginais.length > 0) {
            // Filtra somente imóveis com status = 0 e valores válidos
            const filtrados = ativosOriginais.filter(
                (ativo) =>
                    ativo.status === 0 && // Garantir que o status é 0
                    ativo.valor_vista > 0 &&
                    ativo.valor_inicial_parcela_programada > 0 &&
                    ativo.area > 0
            );

            setAtivosFiltrados(filtrados);

            if (filtrados.length > 0) {
                const novoRange = {
                    valor_vista: {
                        min: Math.min(...filtrados.map((a) => a.valor_vista)),
                        max: Math.max(...filtrados.map((a) => a.valor_vista)),
                    },
                    valor_inicial_parcela_programada: {
                        min: Math.min(...filtrados.map((a) => a.valor_inicial_parcela_programada)),
                        max: Math.max(...filtrados.map((a) => a.valor_inicial_parcela_programada)),
                    },
                    area: {
                        min: Math.min(...filtrados.map((a) => a.area)),
                        max: Math.max(...filtrados.map((a) => a.area)),
                    },
                };
                setRanges(novoRange);
                setFiltros(novoRange);
            }
        }
    }, [ativosOriginais]);

    // Atualiza os filtros e filtra os itens
    useEffect(() => {
        const ativosAtualizados = ativosOriginais.filter((ativo) => {
            const dentroValorVista =
                ativo.valor_vista >= filtros.valor_vista.min && ativo.valor_vista <= filtros.valor_vista.max;
            const dentroParcelas =
                ativo.valor_inicial_parcela_programada >= filtros.valor_inicial_parcela_programada.min &&
                ativo.valor_inicial_parcela_programada <= filtros.valor_inicial_parcela_programada.max;
            const dentroArea =
                ativo.area >= filtros.area.min && ativo.area <= filtros.area.max;

            return ativo.status === 0 && dentroValorVista && dentroParcelas && dentroArea; // Garante que o status continua sendo 0
        });

        setAtivosFiltrados(ativosAtualizados);
    }, [filtros, ativosOriginais]);

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Filtrar Lotes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className="px-5">
                    <Row>
                        <Col className="text-center">
                            <div className="slider-section">
                                <h5 className="mb-4">Valor do Lote</h5>
                                <InputRange
                                    maxValue={ranges.valor_vista.max}
                                    minValue={ranges.valor_vista.min}
                                    step={10000}
                                    value={filtros.valor_vista}
                                    formatLabel={(value) => `R$ ${value.toLocaleString()}`}
                                    onChange={(value) => setFiltros((prev) => ({ ...prev, valor_vista: value }))}
                                />
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col className="text-center">
                            <div className="slider-section">
                                <h5 className="mb-4">Parcelas Programadas</h5>
                                <InputRange
                                    maxValue={ranges.valor_inicial_parcela_programada.max}
                                    minValue={ranges.valor_inicial_parcela_programada.min}
                                    step={50}
                                    value={filtros.valor_inicial_parcela_programada}
                                    formatLabel={(value) => `R$ ${value.toLocaleString()}`}
                                    onChange={(value) => setFiltros((prev) => ({ ...prev, valor_inicial_parcela_programada: value }))}
                                />
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col className="text-center">
                            <div className="slider-section">
                                <h5 className="mb-4">Tamanho do Terreno</h5>
                                <InputRange
                                    maxValue={ranges.area.max}
                                    minValue={ranges.area.min}
                                    step={10}
                                    value={filtros.area}
                                    formatLabel={(value) => `${value} m²`}
                                    onChange={(value) => setFiltros((prev) => ({ ...prev, area: value }))}
                                />
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col className="text-center">
                            <div className="mt-3">
                                <h6>Total de Itens Encontrados: {ativosFiltrados.length}</h6>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-between w-100">
                    <Button variant="secondary" onClick={() => setFiltros(ranges)}>
                        Limpar Filtros
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            onApplyFilters(ativosFiltrados);
                            onHide();
                        }}
                    >
                        Aplicar Filtros
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalFiltro;

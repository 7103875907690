import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

import logoRevoWhite from '~/Images/logo-revo-white.svg';
import appointment from '~/Images/appointment.svg';

import getLinks, { URLV1, URLV2 } from './links';
import { MainNavbar } from './styles';
import {
  FaUser,
  FaCalendar,
  FaLock,
  FaSignOutAlt,
  FaLaptop,
  FaGavel,
  FaDollarSign,
  FaInfo,
} from 'react-icons/fa';

import AuthStorage from '~/Utils/AuthStorage';
import * as api from '~/Services/Service';

export default function NavbarHeader({ onLogout, user }) {
  const [dptoLancamento, setDptoLancamento] = useState(null);
  
  useEffect(() => {
    if (AuthStorage.isAtendenteLancamento())
      buscarMinhasSolicitacoesPendentes();
  }, []);

  const buscarMinhasSolicitacoesPendentes = async () => {
    const response = await api.consultarMinhasSolicitacoesPendentes();
    setDptoLancamento(response);
  };

  const clickDpto = async departamento => {
    const response = await api.NotificarDepartamento(departamento);
    console.log('notificar: ', response);
    setDptoLancamento(response);
  };

  return (
    <MainNavbar fixed="top" expand="lg" variant="dark">
      <Navbar.Brand href={URLV1 + '/default.aspx'}>
        <img src={logoRevoWhite} alt="Revo Brasil" />
      </Navbar.Brand>
      <Navbar.Toggle className="border-0" />

      <Navbar.Collapse>
        <Nav className="mr-auto">
          {getLinks().map((item, index) =>
            item.dropdown ? (
              <NavDropdown
                title={
                  <>
                    {item.icon}
                    <span>{item.label}</span>
                  </>
                }
                id={index}
                key={index}
              >
                {item.dropdown.map((ditem, dindex) =>
                  ditem.divider ? (
                    <NavDropdown.Divider key={dindex} />
                  ) : ditem.header ? (
                    <NavDropdown.Header key={dindex}>
                      {ditem.header}
                    </NavDropdown.Header>
                  ) : (
                    <NavDropdown.Item key={dindex} href={ditem.href}>
                      {ditem.icon}
                      <span>{ditem.label}</span>
                    </NavDropdown.Item>
                  )
                )}
              </NavDropdown>
            ) : (
              <Nav.Link target={item.target} key={index} href={item.href}>
                {item.icon}
                <span>{item.label}</span>
              </Nav.Link>
            )
          )}

          {dptoLancamento && dptoLancamento.imobiliaria && (
            <>
              {/* <div className="nav-item item-dpto">
                <button
                  onClick={() => clickDpto('gerente')}
                  className={`nav-link ${
                    dptoLancamento.imobiliaria.posicao ? 'notificar' : ''
                  }`}
                >
                  {dptoLancamento.imobiliaria.nome}
                </button>
              </div> */}
              <div className="nav-item item-dpto">
                <button
                  onClick={() => clickDpto('corretor')}
                  className={`nav-link ${
                    dptoLancamento.corretor.posicao ? 'notificar' : ''
                  }`}
                >
                  {dptoLancamento.corretor.nome}
                </button>
              </div>
              <div className="nav-item item-dpto">
                <button
                  onClick={() => clickDpto('apoio')}
                  className={`nav-link ${
                    dptoLancamento.apoio ? 'notificar' : ''
                  }`}
                >
                  <FaInfo />
                  <span className="visible-lg-inline">Apoio</span>
                </button>
                {dptoLancamento.apoio && (
                  <div className="posicao">{dptoLancamento.apoio}</div>
                )}
              </div>
              <div className="nav-item item-dpto">
                <button
                  onClick={() => clickDpto('ti')}
                  className={`nav-link ${dptoLancamento.ti ? 'notificar' : ''}`}
                >
                  <FaLaptop /> <span className="visible-lg-inline">TI</span>
                </button>
                {dptoLancamento.ti && (
                  <div className="posicao">{dptoLancamento.ti}</div>
                )}
              </div>
              <div className="nav-item item-dpto">
                <button
                  onClick={() => clickDpto('diretoria')}
                  className={`nav-link ${
                    dptoLancamento.diretoria ? 'notificar' : ''
                  }`}
                >
                  <FaUser />
                  <span className="visible-lg-inline">Diretoria</span>
                </button>
                {dptoLancamento.diretoria && (
                  <div className="posicao">{dptoLancamento.diretoria}</div>
                )}
              </div>
              <div className="nav-item item-dpto">
                <button
                  onClick={() => clickDpto('juridico')}
                  className={`nav-link ${
                    dptoLancamento.juridico ? 'notificar' : ''
                  }`}
                >
                  <FaGavel />
                  <span className="visible-lg-inline">Jurídico</span>
                </button>
                {dptoLancamento.juridico && (
                  <div className="posicao">{dptoLancamento.juridico}</div>
                )}
              </div>
              <div className="nav-item item-dpto">
                <button
                  onClick={() => clickDpto('financeiro')}
                  className={`nav-link ${
                    dptoLancamento.financeiro ? 'notificar' : ''
                  }`}
                >
                  <FaDollarSign />
                  <span className="visible-lg-inline">Financeiro</span>
                </button>
                {dptoLancamento.financeiro && (
                  <div className="posicao">{dptoLancamento.financeiro}</div>
                )}
              </div>
            </>
          )}
        </Nav>

        <Nav className="ml-auto">
          <NavDropdown
            alignRight
            title={
              <>
                <div className='d-flex flex-row justify-content-between w-100'>
                  {/* <div className='mr-3'>
                    Mesa: 78
                  </div> */}
                  <div>
                    <FaUser />
                    {user.nome}
                  </div>
                </div>
              </>
            }
          >
            <NavDropdown.Item
              href={`${URLV1}/Prospectos/Agendamentos/Consultar.aspx`}
            >
              <FaCalendar />
              Agendamentos
            </NavDropdown.Item>
            {false && (
              <NavDropdown.Item href={`${URLV2}/agendamento`}>
                <img src={appointment} alt="Agendamentos" />
                Agendamentos
              </NavDropdown.Item>
            )}
            <NavDropdown.Item href={`${URLV1}/Usuarios/AlterarSenha.aspx`}>
              <FaLock /> Alterar senha
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={onLogout} href={`${URLV1}/Login.aspx`}>
              <FaSignOutAlt />
              Sair
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </MainNavbar>
  );
}

import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Table, Form, Button } from 'react-bootstrap';
import Loading from '../Loading';
import { FaSave, FaTrash, FaCheck } from 'react-icons/fa';
import AuthStorage from '~/Utils/AuthStorage';
import { toast } from 'react-toastify';
import * as api from '~/Services/Service';

const DetalhesPreVendaSemConjuge = ({ preVenda }) => {
  const [mostrarDivAutorizacao, setMostrarDivAutorizacao] = useState(false);
  const [preVendaSemConjuge, setPreVendaSemConjuge] = useState(null);
  const [carregandoDados, setCarregandoDados] = useState(true);
  const [senha, setSenha] = useState('');
  const [isLoading, setIsLoading] = useState(null);

  const habilitarDivAutorizacao = () => {
    setMostrarDivAutorizacao(!mostrarDivAutorizacao);
    setSenha('');
  };

  const bloquearBtnAutorizacao = () => {
    if (
      AuthStorage.getCaracteristicas().find(
        caracteristica => caracteristica === 242
      ) // Autoriza Sem Conjuge
    ) {
      return false;
    }
    return true;
  };

  const obterDadosPreVendaSemConjuge = async preVenda => {
    try {
      setCarregandoDados(true);
      let response = await api.obterDadosPreVendaSemConjuge(preVenda);
      if (response) {
        setPreVendaSemConjuge(response);
      } else {
        setPreVendaSemConjuge(null);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setCarregandoDados(false);
    }
  };

  useEffect(() => {
    obterDadosPreVendaSemConjuge(preVenda);
  }, []);

  const autorizarPreVenda = async () => {
    setIsLoading(true);
    try {
      const request = {
        senha_usuario: senha,
        nome_usuario: AuthStorage.getUser().nome,
        codigo_pre_venda: preVenda,
        codigo_conta_cliente: preVendaSemConjuge.conta_cliente,
        ge: preVendaSemConjuge.ge,
      };

      let response = await api.autorizarPreVendaSemConjuge(request);
      if (response) {
        toast.success(response.message);
        obterDadosPreVendaSemConjuge(preVenda);
        habilitarDivAutorizacao();
      }
    } catch (error) {
      toast.error(error.message);
    }finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {!carregandoDados && (
        <>
          <div className="mt-1">
            <h5>Administração de Pré-Venda Sem Cônjuge</h5>
          </div>

          <div className="table-responsive">
            <Table striped bordered hover className="table-sm">
              <thead>
                <tr className="text-center">
                  <th>Usuário</th>
                  <th>Quadra</th>
                  <th>Lote</th>
                  <th>Empreendimento</th>
                  <th>GE</th>
                  <th>Conta Cliente</th>
                  <th>Nome</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {preVendaSemConjuge && (
                  <tr className="text-center">
                    <td>{preVendaSemConjuge.usuario_criacao}</td>
                    <td>{preVendaSemConjuge.quadra}</td>
                    <td>{preVendaSemConjuge.lote}</td>
                    <td>{preVendaSemConjuge.empreendimento}</td>
                    <td>{preVendaSemConjuge.ge}</td>
                    <td>{preVendaSemConjuge.conta_cliente}</td>
                    <td>{preVendaSemConjuge.nome_cliente}</td>
                    <td className="text-center align-middle">
                      {
                        <>
                          <Button
                            variant="success"
                            type="submit"
                            className="d-inline-flex align-items-center"
                            onClick={() => {
                              habilitarDivAutorizacao();
                            }}
                            disabled={bloquearBtnAutorizacao()}
                          >
                            <FaCheck />
                          </Button>
                        </>
                      }{' '}
                    </td>
                  </tr>
                )}
                {!preVendaSemConjuge && (
                  <tr>
                    <td colSpan="9" className="text-center">
                      Nenhuma autorização disponível para essa pré-venda.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {mostrarDivAutorizacao && (
            <>
              <hr />
              <strong>Digite a senha para autorizar a pré-venda.</strong>
              <Row className="mt-2">
                <Col xs={12} sm={6} md={6}>
                  <Form.Group controlId="usuario">
                    <Form.Label className="ms-auto">Usuário</Form.Label>
                    <Form.Control
                      disabled={true}
                      value={AuthStorage.getUser().nome}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6} md={6}>
                  <Form.Group controlId="senha">
                    <Form.Label className="ms-auto">Senha</Form.Label>
                    <Form.Control
                      type="password"
                      onChange={e => {
                        setSenha(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-end">
                <Col xs={12} sm={6} md={'auto'}>
                  <Button
                    variant="secondary"
                    type="submit"
                    className="d-flex align-items-center"
                    onClick={() => {
                      setMostrarDivAutorizacao();
                    }}
                  >
                    <FaTrash />
                    <span className="ml-2 d-xs-none">Cancelar</span>
                  </Button>
                </Col>
                <Col xs={12} sm={6} md={'auto'}>
                  <Button
                    variant="success"
                    type="submit"
                    className="d-flex align-items-center"
                    onClick={() => {
                      autorizarPreVenda();
                    }}
                    disabled={!senha.trim() || isLoading}
                  >
                    <FaSave />
                    <span className="ml-2 d-xs-none">Confirmar</span>
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
      {carregandoDados && (
        <div>
          <Loading></Loading>
        </div>
      )}
    </>
  );
};

export default DetalhesPreVendaSemConjuge;

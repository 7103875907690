import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert, Container, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import qs from 'qs';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { FiAlertTriangle } from 'react-icons/fi';
import {
  fetchProspectoDetalhes,
  getAtualizarCidades,
  buscarOpcoesProspecto,
  atualizarProspecto,
  criaContaGE,
  atualizarStatusProspecto,
  removerEmpreendimento,
  selecionarEmpreendimento,
  setProspecto,
  reImportarSuaHouse,
  dismissAvisoDescarteSuaHouse,
} from '~/Store/Prospecto/actions';
import Loading from '~/Components/Loading';
import SelectEmpreendimentoProspecto from '~/Components/Prospectos/ProspectoDetalhes/SelectEmpreendimentoProspecto';
import FormDadosPessoais from '~/Components/Prospectos/ProspectoDetalhes/FormDadosPessoais';
import CardNavigationTab from '~/Components/CardNavigationTab';
import ListaSocios from '~/Components/Prospectos/ProspectoDetalhes/ListaSocios';
// import Pesquisa from '~/Components/Prospectos/ProspectoDetalhes/Pesquisa/Pesquisa';
import CamposDocumentos from '~/Components/Prospectos/ProspectoDetalhes/CamposDocumentos/CamposDocumentos';
import Ocorrencias from '~/Components/Prospectos/ProspectoDetalhes/Ocorrencias/Ocorrencias';
import AuthStorage from '~/Utils/AuthStorage';
import Prospectos from '~/Components/Prospectos/Prospectos';
import HeaderProspectoDetalhes from '~/Components/Prospectos/ProspectoDetalhes/HeaderProspectoDetalhes/HeaderProspectoDetalhes';
import schemaToValidation from '~/Components/Prospectos/ProspectoDetalhes/ProspectoSchemaValidacoes';
import isRequiredField from '~/Utils/isRequiredField';
import dialog from '~/Utils/dialog';

function ProspectoDetalhes({
  atualizandoProspectoEmpreendimento,
  erroAtualizarProspectoEmpreendimento,
  hiddenSearch,
  location,
  prospectoID,
  buscarOpcoesProspecto,
  fetchProspectoDetalhes,
  setProspecto,
  criaContaGE,
  atualizarProspecto,
  reImportarSuaHouse,
  dismissAvisoDescarteSuaHouse,
  atualizarStatusProspecto,
  removerEmpreendimento,
  selecionarEmpreendimento,
  getAtualizarCidades,
  onCancel,
  empreendimento,
  hiddenSelectEmpreendimento = false,
  readonly = false, //TODO
  liberarConsulta = false,
}) {
  const empreendimentos = useSelector(state => state.empreendimentos);
  const gerentesCorretores = useSelector(state => state.corretor);
  const options = useSelector(state => state.options);
  const {
    detail: prospecto,
    sucessoAtualizarProspectoEmpreendimento,
    isLoadingDetail,
    isErrorDetail,
    isLoadingOptions,
    criandoProspecto,
    erroCriandoProspecto,
    prospectoCriado,
  } = useSelector(state => state.prospecto);
  const isReadonly = readonly || AuthStorage.temCaracteristica(495);

  const [tab, setTab] = useState('dados-cliente');

  useEffect(() => {
    buscarOpcoesProspecto();
  }, []);

  useEffect(() => {
    const { Familia } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    let empreendimentoID = Familia || empreendimento;

    if (prospectoID) {
      fetchProspectoDetalhes(prospectoID, empreendimentoID, liberarConsulta);
    }
  }, [prospectoID]);

  //Altera a aba quando altera o empreendimento
  useEffect(() => {
    setTab('dados-cliente');
  }, [prospecto.codigo_familia]);

  useEffect(() => {
    //TO DO mudar rota quando mudar empreendimento
    let urlParams = new URLSearchParams(location.search);
    if (urlParams.get('PV') && !prospecto.pre_venda) {
      setProspecto('PreVenda', urlParams.get('PV'));
    }
  }, [location.search]);

  useEffect(() => {
    if (prospectoCriado) {
      toast.success('Os dados foram salvos com sucesso');
    }
  }, [prospectoCriado]);

  useEffect(() => {
    if (erroCriandoProspecto) toast.error(erroCriandoProspecto);
  }, [erroCriandoProspecto]);

  const salvarProspecto = values => {
    if (prospecto.codigo) {
      if (values.TentouValidar && !prospecto.permitidoAdicionarDocumento) {
        criaContaGE(values);
      } else {
        atualizarProspecto(values);
      }
    }
  };

  const onReImportarSuaHouse = async codigoProspecto => {
    const result = await dialog.confirm({
      title: 'Confirmar Sincronização',
      message:
        'Esta ação sobrescreverá os dados do prospecto na Extranet com os dados do cliente correspondente na Sua House CRM. Deseja continuar?',
    });
    if (!result.proceed) return;

    reImportarSuaHouse(codigoProspecto);
  };

  const verificaTab = async tab => {
    if (
      !prospecto.permitidoAdicionarDocumento &&
      (tab === 'documentos' || tab === 'socios')
    ) {
      await formProspecto.setFieldValue('TentouValidar', true);
      formProspecto.handleSubmit();

      return;
    }

    setTab(tab);
  };

  const formProspecto = useFormik({
    enableReinitialize: true,
    initialValues: {
      tipo_pessoa: 0,
      tipo: '',
      nome: '',
      cpf: '',
      email: '',
      tel_celular: '',
      tel_residencial: '',
      tel_comercial: '',
      estado_civil: '',
      sexo: '',
      rg: '',
      data_emissao_rg: '',
      cnh: '',
      orgao_emissor_cnh: '',
      nascimento: '',
      codigo_status: '',
      cep: '',
      estado: '',
      bairro: '',
      endereco: '',
      numero: '',
      complemento: '',
      orgao_emissor_rg: '',
      perfil_exterior_telefone: '',
      perfil_exterior_ddi: '',
      perfil_exterior_pais: '',
      perfil_exterior_endereco: '',
      ...prospecto,
    },
    onSubmit: salvarProspecto,
    validationSchema: schemaToValidation,
  });

  const modoEditarProspecto =
    prospecto &&
    ((prospecto.prospecto_empreendimento &&
      prospecto.prospecto_empreendimento.length > 0) ||
      (prospecto.codigo_familia &&
        prospecto.codigo_gerente &&
        prospecto.codigo_corretor));

  const initialValues = prospecto;

  if (
    AuthStorage.isCorretor() &&
    gerentesCorretores.corretoresById[AuthStorage.getUser().usuario]
  ) {
    let corretor =
      gerentesCorretores.corretoresById[AuthStorage.getUser().usuario];
    initialValues.codigo_gerente = corretor.codigo_gerente;
    initialValues.codigo_corretor = corretor.codigo;
  }

  return (
    <Prospectos hiddenSearch={hiddenSearch}>
      {() => {
        if (!isLoadingDetail && isErrorDetail)
          return (
            <div className="mx-auto d-flex justify-content-center w-100 mt-4">
              <Alert variant="warning ">
                Erro ao carregar os detalhes do prospecto
              </Alert>
            </div>
          );

        if (isLoadingDetail || isLoadingOptions) return <Loading />;

        return (
          <div className="ProspectoDetalhes">
            <Container fluid>
              <Row>
                <Col md={hiddenSelectEmpreendimento ? 12 : 4}>
                  <HeaderProspectoDetalhes prospecto={prospecto} />
                </Col>
                {!hiddenSelectEmpreendimento && (
                  <Col md="8">
                    <SelectEmpreendimentoProspecto
                      empreendimentos={empreendimentos}
                      prospecto={prospecto}
                      atualizarStatusProspecto={atualizarStatusProspecto}
                      removerEmpreendimento={removerEmpreendimento}
                      atualizandoProspectoEmpreendimento={
                        atualizandoProspectoEmpreendimento
                      }
                      erroAtualizarProspectoEmpreendimento={
                        erroAtualizarProspectoEmpreendimento
                      }
                      sucessoAtualizarProspectoEmpreendimento={
                        sucessoAtualizarProspectoEmpreendimento
                      }
                      selecionarEmpreendimento={selecionarEmpreendimento}
                      setProspecto={setProspecto}
                      gerentesCorretores={gerentesCorretores}
                      motivosDescarte={options.motivosDescarte}
                      onDismissAvisoDescarteSuaHouse={
                        dismissAvisoDescarteSuaHouse
                      }
                      exibeBotoes={!isReadonly}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col className="py-3">
                  {modoEditarProspecto && (
                    <CardNavigationTab
                      activeTab={tab}
                      onChangeTab={verificaTab}
                      tabs={[
                        {
                          label: 'Dados do cliente',
                          url: 'dados-cliente',
                          component: (
                            <FormDadosPessoais
                              permitidoAdicionarDocumento={
                                prospecto.permitidoAdicionarDocumento
                              }
                              formProspecto={formProspecto}
                              isRequiredField={field =>
                                isRequiredField(field, schemaToValidation)
                              }
                              onCancel={onCancel}
                              isSubmitting={criandoProspecto}
                              getAtualizarCidades={getAtualizarCidades}
                              onReImportarSuaHouse={onReImportarSuaHouse}
                              options={options}
                            />
                          ),
                        },
                        {
                          label: (
                            <>
                              {!prospecto.permitidoAdicionarDocumento && (
                                <FiAlertTriangle
                                  color="#333"
                                  className="mr-2"
                                />
                              )}
                              Cônjuge/Sócio
                            </>
                          ),
                          url: 'socios',
                          component: <ListaSocios />,
                          isDisabled: criandoProspecto,
                        },
                        {
                          label: (
                            <>
                              {!prospecto.permitidoAdicionarDocumento && (
                                <FiAlertTriangle
                                  color="#333"
                                  className="mr-2"
                                />
                              )}
                              Documentos
                            </>
                          ),
                          url: 'documentos',
                          component: <CamposDocumentos />,
                          isDisabled: criandoProspecto || isReadonly,
                        },
                        // {
                        //   label: 'Pesquisa',
                        //   url: 'pesquisa',
                        //   component: <Pesquisa />,
                        //   isDisabled: criandoProspecto,
                        // },
                        {
                          label: 'Ocorrências',
                          url: 'ocorrencias',
                          component: <Ocorrencias />,
                          isDisabled: criandoProspecto,
                        },
                      ]}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        );
      }}
    </Prospectos>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProspectoDetalhes,
      setProspecto,
      getAtualizarCidades,
      atualizarProspecto,
      buscarOpcoesProspecto,
      criaContaGE,
      atualizarStatusProspecto,
      removerEmpreendimento,
      selecionarEmpreendimento,
      reImportarSuaHouse,
      dismissAvisoDescarteSuaHouse,
    },
    dispatch
  );

export default withRouter(connect(null, mapDispatchToProps)(ProspectoDetalhes));

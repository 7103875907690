import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { NIVELADM } from '~/constant';

import App from '~/Components/App';
import Login from '~/Pages/Login';
import ProspectoLista from '~/Pages/ProspectoLista';
import ProspectoDetalhes from '~/Pages/ProspectoDetalhes';
import Produtividade from '~/Pages/Produtividade';
import Comissao from '~/Pages/Comissao';
import Vendas from '~/Pages/Vendas';
import AnexarComprovante from '~/Pages/AnexarComprovante';
import AtendimentoCorretor from '~/Pages/AtendimentoCorretor';
import AtendimentoGerente from '~/Pages/AtendimentoGerente';
import LancamentoSolicitacoes from '~/Pages/LancamentoSolicitacoes/LancamentoSolicitacoes';
import LancamentoAvaliacao from '~/Pages/LancamentoAvaliacao/LancamentoAvaliacao';
import PesquisaPosVenda from '~/Pages/PesquisaPosVenda';
import LancamentoSequenciaAtendimento from '~/Pages/LancamentoSequenciaAtendimento/LancamentoSequenciaAtendimento';
import PropostaEstorno from '~/Pages/Proposta/PropostaEstorno';
import RankingCorretores from '~/Pages/RankingCorretores';
import RankingCorretoresTrimestre from '~/Pages/RankingCorretoresTrimestre';
import PrivateRoute from './PrivateRoute';
import Prospectos from '~/Pages/Prospectos';
import AtribuirProspectos from '~/Pages/AtribuirProspectos';
import Agendamento from '~/Pages/Agendamento';
import ConfiguracoesFluxoProspecto from '~/Pages/Configuracoes/FluxoProspecto';
import GerenciamentoRankingCampanha from '~/Pages/Configuracoes/GerenciamentoRankingCampanha';
import DocumentoDigitalOld from '~/Pages/DocumentoDigital';
import DocumentoDigital from '~/Pages/DocumentoDigital';
import CobrancaInadimplencia from '~/Pages/CobrancaInadimplencia';
import AutorizacaoVenda from '~/Pages/AutorizacaoVenda';
import TestPage from '~/Pages/TestPage';
import ImportarLead from '~/Pages/ImportarLead/ImportarLead';
import ConsultarDescontos from '~/Pages/ConsultarDescontos';

import AuthStorage from '~/Utils/AuthStorage';
import CardSituacaoDetalhada from '~/Components/StatusSituacaoCliente/CardSituacaoDetalhada';
import EditorMioloContrato from '~/Pages/EditarMioloContrato';
import mapaHumanizado from '~/Pages/MapaHumanizado/Index';
import VendaGravada from '~/Components/Vendas/VendaGravada';
import AdmVendas from '~/Pages/AdmVendas/index';
import NotificaoLancamento from '~/Components/NotificacaoLancamento';

const Routes = () => (
  <App>
    <Switch>
      <PrivateRoute hasDashboard path="/agendamento" component={Agendamento} />
      <PrivateRoute
        hasDashboard
        path="/pesquisa"
        component={LancamentoAvaliacao}
      />
      <PrivateRoute
        hasDashboard
        path="/cliente/pesquisa/contratos/:codigo_boleto"
        component={PesquisaPosVenda}
      />
      <PrivateRoute
        hasDashboard
        path="/cliente/pesquisa/situacao/:cpf"
        component={CardSituacaoDetalhada}
      />
      <PrivateRoute
        hasDashboard
        path="/lancamento/atendimento/corretor"
        component={AtendimentoCorretor}
      />
      <PrivateRoute
        hasDashboard
        path="/lancamento/atendimento/gerente"
        component={AtendimentoGerente}
      />
      <Route
        path="/lancamento/solicitacoes"
        component={LancamentoSolicitacoes}
      />
      <Route
        path="/lancamento/compensacao"
        component={LancamentoSequenciaAtendimento}
      />
      <PrivateRoute
        hasDashboard
        path="/comercial/arquivos/ge/:ge/prevenda/:prevenda"
        component={AnexarComprovante}
      />
      <PrivateRoute
        hasDashboard
        path="/comercial/relatorio/produtividade"
        component={Produtividade}
      />
      <PrivateRoute
        hasDashboard
        path="/comercial/relatorio/comissao"
        component={Comissao}
      />
      <PrivateRoute
        path="/comercial/ranking/corretor"
        component={RankingCorretores}
        hasDashboard
      />
      <PrivateRoute
        path="/comercial/ranking/trimestre"
        component={RankingCorretoresTrimestre}
        hasDashboard
      />
      <PrivateRoute
        path="/vendas"
        component={Vendas}
        authorizationEvaluator={
          () => AuthStorage.temCaracteristicas([196]) //o intuito dessa rota é realizar aprovações
        }
        exact
        hasDashboard
      />
      <PrivateRoute
        path="/vendas/prospecto/:prospecto/empreendimento/:empreendimento"
        component={Vendas}
        authorizationEvaluator={() => AuthStorage.temCaracteristicas([216])}
        exact
        hasDashboard
      />
      <PrivateRoute
        path="/vendas/autorizacao"
        component={AutorizacaoVenda}
        authorizationEvaluator={() => AuthStorage.temCaracteristicas([196])}
        exact
        hasDashboard
      />
      <PrivateRoute path="/comercial/ranking" component={RankingCorretores} />
      <PrivateRoute
        hasDashboard
        path="/prospectos/lista"
        exact
        component={ProspectoLista}
      />
      <PrivateRoute
        hasDashboard
        path="/prospectos/lista/:id/:tab?/:subtab?"
        component={ProspectoDetalhes}
      />
      <Redirect
        path="/prospectos/:id/:tab?/:subtab?"
        to="/prospectos/lista/:id"
      />
      <PrivateRoute hasDashboard path="/prospectos" component={Prospectos} />
      <PrivateRoute
        hasDashboard
        path="/atribuir/prospecto"
        authorizationEvaluator={() =>
          AuthStorage.temCaracteristicas([201, 199])
        }
        component={AtribuirProspectos}
      />
      <PrivateRoute
        hasDashboard
        path="/documentodigitalold"
        component={DocumentoDigitalOld}
      />
      <PrivateRoute
        hasDashboard
        path="/documentodigital"
        component={DocumentoDigital}
      />
      <PrivateRoute
        hasDashboard
        path="/proposta/estorno/solicitacoes"
        level={[NIVELADM]}
        component={PropostaEstorno}
      />
      <PrivateRoute
        hasDashboard
        path="/configuracoes/prospecto/fluxo"
        component={ConfiguracoesFluxoProspecto}
      />
      <PrivateRoute
        hasDashboard
        path="/configuracoes/campanha"
        component={GerenciamentoRankingCampanha}
        authorizationEvaluator={() =>
          AuthStorage.temCaracteristicaGerParametros()
        }
      />
      <PrivateRoute
        hasDashboard
        path="/inadimplencia"
        component={CobrancaInadimplencia}
      />
      <PrivateRoute
        hasDashboard
        path="/crm/importar"
        component={ImportarLead}
      />
      <Route path="/login" component={Login} />
      <PrivateRoute hasDashboard path="/test" component={TestPage} />
      <PrivateRoute hasDashboard path="/contrato/empreendimento/:id" component={EditorMioloContrato} />
      <PrivateRoute hasDashboard path="/mapa" component={mapaHumanizado} />
      <Route hasDashboard path="/notificacao/departamento" component={NotificaoLancamento} />
      <PrivateRoute hasDashboard path="/ConsultarDesconto" component={ConsultarDescontos} />
      <PrivateRoute hasDashboard path="/AdministracaoVendas" component={AdmVendas} />
      <Redirect to="/comercial/ranking/corretor" />
    </Switch>
  </App>
);

export default Routes;

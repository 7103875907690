import { Modal, Button } from 'react-bootstrap';
import React from 'react';
import DocumentoDigital from '~/Pages/DocumentoDigital';
import { FaWindowClose } from 'react-icons/fa';

const ModalAssinatura = ({
  mostrarModal,
  setMostrarModal,
  codPreVenda,
  codDocDigital,
  setCodigoDocDigital,
}) => {
  return (
    <Modal
      show={mostrarModal}
      onHide={() => {
        setMostrarModal(false);
        setCodigoDocDigital(0);
      }}
      size={'xl'}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Assinatura digital</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: '#f1f1f1' }}>
        <DocumentoDigital
          codigoPreVenda={codPreVenda}
          codigoDocumentoDigital={codDocDigital}
        />
      </Modal.Body>
      <Modal.Footer>
        <>
          <Button
            variant={'light'}
            type="submit"
            className="d-inline-flex align-items-center"
            onClick={() => {
              setMostrarModal(false);
            }}
          >
            <FaWindowClose />
            <span className="ml-2 d-xs-none">Fechar</span>
          </Button>
        </>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAssinatura;
